<template>
    <div class="text-gray-400 grid grid-cols-12">
        <div class="col-span-9 p-5 border-r border-gray-800">
            <div class="mb-5 relative flex items-center">
                <input v-model="search" type="text" class="form-control bg-gray-800 border-gray-700 placeholder-gray-600" placeholder="Cari nama stasiun">
                <a v-show="search" href="#" @click.prevent="search = ''" class="absolute right-3 hover:text-red-500"><i class="icon-cancel-circle2"></i></a>
            </div>
            <div class="grid grid-cols-2 gap-3">
                <div v-for="(station, i) in stationFilter" :key="i" class="col-span-1 cursor-pointer">
                    <div v-if="sensor[station.stationId]" @click="onDetail(station)" class="bg-gray-800 bg-opacity-80 rounded-sm overflow-hidden border border-transparent hover:border-green-500">
                        <div  class="py-1 px-2 bg-gray-700 bg-opacity-30 flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="mr-2">{{station.name}}</div>
                                <div v-if="station.status == 'connected'" class="w-3 h-3 rounded-full bg-green-500"></div>
                                <div v-if="station.status == 'disconnected'" class="w-3 h-3 rounded-full bg-red-500"></div>
                                <div v-if="station.status == 'maintenence'" class="w-3 h-3 rounded-full bg-yellow-500"></div>
                            </div>
                            <div v-if="sensor[station.stationId]['modbus']" class="text-red-500 flex items-center">{{sensor[station.stationId]['modbus']['temp']}} ℃</div>
                            <div v-else class="text-red-500 flex items-center">-- ℃</div>
                        </div>
                        <div class="p-3 grid grid-cols-3 gap-3">
                            <div class="p-2 border border-cyan-700 rounded-sm bg-cyan-600 text-cyan-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-cyan-600 text-cyan-200 px-1.5 py-0.5 rounded-sm">pH</span></div>
                                    <div  v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium">{{sensor[station.stationId]['modbus']['ph']}}</div>
                                    <div  v-else class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-cyan-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgPh).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-cyan-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{station.logger[0].maxPh}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2 border border-purple-700 rounded-sm bg-purple-500 text-purple-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-purple-600 text-purple-200 px-1.5 py-0.5 rounded-sm">DO</span></div>
                                    <div v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium">{{sensor[station.stationId]['modbus']['do']}}</div>
                                    <div v-else class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-purple-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgDo).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-purple-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxDo).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-orange-700 p-2 rounded-sm bg-orange-600 text-orange-50 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-orange-600 text-orange-200 px-1.5 py-0.5 rounded-sm">Turb</span></div>
                                    <div v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium text-orange-500">{{sensor[station.stationId]['modbus']['turb']}}</div>
                                    <div v-else class="text-lg font-medium text-orange-500">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-orange-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgTurb).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-orange-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxTurb).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-3 pb-3 grid grid-cols-3 gap-3">
                            <div class="p-2 border border-pink-700 rounded-sm bg-pink-600 text-pink-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-pink-600 text-pink-200 px-1.5 py-0.5 rounded-sm">Salt</span></div>
                                    <div v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium">{{sensor[station.stationId]['modbus']['salt']}}</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-pink-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgSalt).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-pink-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxSalt).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2 border border-gray-700 rounded-sm bg-gray-500 text-gray-400 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-gray-600 text-gray-200 px-1.5 py-0.5 rounded-sm">Cond</span></div>
                                    <div v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium">{{sensor[station.stationId]['modbus']['cond']}}</div>
                                    <div v-else class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-gray-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgCond).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-gray-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxCond).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-indigo-700 p-2 rounded-sm bg-indigo-600 text-indigo-50 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-indigo-600 text-indigo-200 px-1.5 py-0.5 rounded-sm">Dept</span></div>
                                    <div v-if="sensor[station.stationId]['modbus']" class="text-lg font-medium text-indigo-500">{{sensor[station.stationId]['modbus']['dept']}}</div>
                                    <div v-else class="text-lg font-medium text-indigo-500">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-indigo-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgDept).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-indigo-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].avgCond).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else @click="onDetail(station)" class="bg-gray-800 bg-opacity-80 rounded-sm overflow-hidden border border-transparent hover:border-green-500">
                        <div  class="py-1 px-2 bg-gray-700 bg-opacity-30 flex items-center justify-between">
                            <div class="flex items-center">
                                <div class="mr-2">{{station.name}}</div>
                                <div v-if="station.status == 'connected'" class="w-3 h-3 rounded-full bg-green-500"></div>
                                <div v-if="station.status == 'disconnected'" class="w-3 h-3 rounded-full bg-red-500"></div>
                                <div v-if="station.status == 'maintenence'" class="w-3 h-3 rounded-full bg-yellow-500"></div>
                            </div>
                            <div class="text-red-500 flex items-center">-- ℃</div>
                        </div>
                        <div class="p-3 grid grid-cols-3 gap-3">
                            <div class="p-2 border border-cyan-700 rounded-sm bg-cyan-600 text-cyan-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-cyan-600 text-cyan-200 px-1.5 py-0.5 rounded-sm">pH</span></div>
                                    <div class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-cyan-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgPh).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-cyan-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{station.logger[0].maxPh}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2 border border-purple-700 rounded-sm bg-purple-500 text-purple-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-purple-600 text-purple-200 px-1.5 py-0.5 rounded-sm">DO</span></div>
                                    <div class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-purple-200">avg</div>
                                        <div class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-purple-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxDo).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-orange-700 p-2 rounded-sm bg-orange-600 text-orange-50 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-orange-600 text-orange-200 px-1.5 py-0.5 rounded-sm">Turb</span></div>
                                    <div class="text-lg font-medium text-orange-500">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-orange-200">avg</div>
                                        <div class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-orange-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxTurb).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-3 pb-3 grid grid-cols-3 gap-3">
                            <div class="p-2 border border-pink-700 rounded-sm bg-pink-600 text-pink-500 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-pink-600 text-pink-200 px-1.5 py-0.5 rounded-sm">Salt</span></div>
                                    <div class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-pink-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgSalt).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-pink-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxSalt).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2 border border-gray-700 rounded-sm bg-gray-500 text-gray-400 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-gray-600 text-gray-200 px-1.5 py-0.5 rounded-sm">Cond</span></div>
                                    <div class="text-lg font-medium">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-gray-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgCond).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-gray-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].maxCond).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-indigo-700 p-2 rounded-sm bg-indigo-600 text-indigo-50 bg-opacity-10">
                                <div class="flex justify-between">
                                    <div><span class="bg-indigo-600 text-indigo-200 px-1.5 py-0.5 rounded-sm">Dept</span></div>
                                    <div class="text-lg font-medium text-indigo-500">--</div>
                                </div>
                                <div class="flex justify-between">
                                    <div class="text-xs text-center">
                                        <div class="text-indigo-200">avg</div>
                                        <div v-if="station.logger[0]" class="text-green-500">{{(station.logger[0].avgDept).toFixed(2)}}</div>
                                        <div v-else class="text-green-500">--</div>
                                    </div>
                                    <div class="text-xs text-center">
                                        <div class="text-indigo-200">max</div>
                                        <div v-if="station.logger[0]" class="text-red-500">{{(station.logger[0].avgCond).toFixed(2)}}</div>
                                        <div v-else class="text-red-500">--</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="activity" class="col-span-3">
            <div ref="warning" class="fixed">
                <div class="px-5 py-3 border-b border-gray-800 w-full">AKTIVITAS</div>
                <ul ref="content" class="overflow-y-scroll">
                    <li v-for="(notif, i) in notificationReverse" :key="i" class="border-b border-gray-800 py-3 px-5">
                        <div v-if="notif.type == 'warning'" class="py-2">
                            <div class="flex">
                                <div>
                                    <i v-if="notif.content.threshold == 'max'" class="icon-warning22 text-red-400"></i>
                                    <i v-if="notif.content.threshold == 'min'" class="icon-warning22 text-yellow-400"></i>
                                </div>
                                <div class="w-full ml-3">
                                    <div class="flex items-center justify-between w-full">
                                        <div>{{notif.content.sensor}}: {{notif.content.value}}</div>
                                        <div class="text-xs text-cyan-500">{{getDate(notif.createdAt)}}</div>
                                    </div>
                                    <div>{{notif.content.name}}</div>
                                    <div class="text-xs text-gray-400">{{notif.content.description}}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="notif.type == 'activity'" class="py-2">
                            <div class="flex">
                                <div>
                                    <i v-if="notif.content.event == 'disconnected'" class="icon-blocked text-red-400"></i>
                                    <i v-if="notif.content.event == 'connected'" class="icon-connection text-green-400"></i>
                                </div>
                                <div class="w-full ml-3">
                                    <div class="flex items-center justify-between w-full">
                                        <div>{{notif.content.event}}</div>
                                        <div class="text-xs text-cyan-500">{{getDate(notif.createdAt)}}</div>
                                    </div>
                                    <div>{{notif.content.name}}</div>
                                    <div class="text-xs text-gray-400">{{notif.content.message}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div ref="bottom" class="h-9 bottom-0 fixed"></div>
        <detail :show="modalDetail" :value="station" @onClose="onCloseDetail"></detail>
    </div>
</template>

<script>
import Detail from '../components/detailstations.vue'
import axios from 'axios'
import { db } from '../db'
import {notification} from '../pusher'
import {moment} from '../moment'
export default {
    components: {Detail},
    data () {
        return {
            search: '',
            station: '',
            stations: [],
            sensor: {},
            modalDetail: false,
            notifications: []
        }
    },
    firebase: {
        sensor: db.ref('dlhJabar')
    },
    mounted () {
        let app = this
        const wd = this.$refs.activity.clientWidth
        const hg = this.$refs.bottom.offsetTop - 97
        this.$refs.warning.style.width = wd+'px'
        this.$refs.content.style.height = hg+'px'
        this.getData()
        notification.bind('activity', function(data) {
            app.notifications.push(data.message)
        })
        notification.bind('warning', function(data) {
            app.notifications.push(data.message)
        })
    },
    computed: {
        stationFilter () {
            return this.stations.filter(obj => {
                return obj.name.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        notificationReverse () {
            return this.notifications.slice().reverse()
        }
    },
    methods: {
        getData () {
            axios.get('/streamings')
            .then(res => {
                this.stations = res.data
            })
        },
        getDate (d) {
            return moment (d)
        },
        onDetail (station) {
            this.modalDetail = true
            this.station = station
        },
        onCloseDetail () {
            this.modalDetail = false
        }
    },
}
</script>
