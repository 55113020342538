import firebase from 'firebase/compat/app'
import 'firebase/compat/database'


const firebaseConfig = {
    apiKey: "AIzaSyDk6hC3D_6HRYlS2Wi5Ucklhibr7VWDo2s",
    authDomain: "onlimo-ba6e3.firebaseapp.com",
    databaseURL: 'https://onlimo-ba6e3-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: "onlimo-ba6e3",
    storageBucket: "onlimo-ba6e3.appspot.com",
    messagingSenderId: "677077417130",
    appId: "1:677077417130:web:b7e6f022ed762f137264d3"
  };

const app = firebase.initializeApp(firebaseConfig);
export const db = app.database()
