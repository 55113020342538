<template>
    <div class="m-5 text-gray-400">
        <div class="flex items-center justify-between mb-5">
            <div class="w-96 relative flex items-center">
                <input v-model="search" type="text" class="form-control bg-gray-800 bg-opacity-30 border-gray-700 placeholder-gray-600" placeholder="Cari segmen">
                <a v-show="search" @click="search=''" href="#" class="absolute text-red-600 hover:text-red-700 right-3"><i class="icon-cancel-circle2"></i></a>
            </div>
            <div>
                <button @click="onAddSegment" class="bg-blue-600 h-9 px-5 text-green-50 rounded-sm">TAMBAH SEGMEN BARU</button>
            </div>
        </div>
        <div v-for="(segment, s) in filterSegments" :key="s" class="mb-5 rounded-sm bg-gray-900">
            <div class="px-3 flex py-2 items-center bg-gray-800 rounded-t-sm">
                <i class="icon-air text-yellow-300"></i>
                <div class="group flex items-center border border-dashed border-opacity-0 border-gray-600 hover:border-opacity-100">
                    <div class="uppercase text-yellow-300 font-semibold text-opacity-80 ml-2">{{segment.name}}</div>
                    <div class="ml-3 flex items-center">
                        <tooltip :text="'Edit Segmen'" :place="'right'">
                            <button @click="onEditSegment(segment)" class="text-gray-500 flex items-center justify-center border-l border-dashed border-gray-600 opacity-0 border-opacity-0 group-hover:border-opacity-100 group-hover:opacity-100 p-1"><i class="icon-pencil"></i></button>
                        </tooltip>
                    </div>
                </div>
                <button @click="onCreate(segment)" class="bg-green-500 h-8 px-3 ml-auto rounded-sm text-green-50 flex items-center justify-center"><i class="icon-add mr-2"></i>Tambah stasiun</button>
            </div>
            <div class="bg-gray-800 bg-opacity-50 p-3 rounded-b-sm">
                <div class="grid grid-cols-2 gap-3">
                    <div v-for="(station, i) in segment.stations" :key="i" class="border border-gray-700 rounded-sm p-3">
                        <div class="flex items-center justify-between mb-5">
                            <div class="uppercase flex items-center">
                                <i class="icon-station" style="font-size: 32px"></i>
                                <div class="ml-3">
                                    <div class="font-medium text-orange-500">{{station.name}}</div>
                                    <div class="text-xs">ID: {{station.stationId}}</div>
                                </div>
                            </div>
                            <div>
                                <tooltip class="mr-3" :text="'Configurasi status'" :place="'right'">
                                    <button @click="onSetStatus(station)" class="bg-gray-800 border border-gray-600 h-7 w-7 rounded-sm text-green-50 flex items-center justify-center"><i class="icon-cog3"></i></button>
                                </tooltip>
                                <tooltip :text="'Edit Stasiun'" :place="'right'">
                                    <button @click="onEdit(segment, station)" class="bg-gray-800 border border-gray-600 h-7 w-7 rounded-sm text-green-50 flex items-center justify-center"><i class="icon-pencil"></i></button>
                                </tooltip>
                            </div>
                        </div>
                        <div class="grid grid-cols-5 gap-1">
                            <div class="col-span-2">
                                <img :src="imgURL+'/'+station.image" width="200" class="rounded-sm" alt="">
                            </div>
                            <div class="col-span-3 text-xs relative">
                                <table class="w-full">
                                    <tr>
                                        <td class="align-top" style="width: 20%">Alamat</td>
                                        <td class="align-top">:</td>
                                        <td class="pl-1">{{station.address}}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-top" style="width: 20%">Koordinat</td>
                                        <td class="align-top">:</td>
                                        <td class="pl-1">{{station.coordinates[0]}}, {{station.coordinates[1]}}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-top" style="width: 20%">IDPEL</td>
                                        <td class="align-top">:</td>
                                        <td class="pl-1">{{station.idpel}}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-top" style="width: 20%">SIM</td>
                                        <td class="align-top">:</td>
                                        <td class="pl-1">{{station.sim}}</td>
                                    </tr>
                                </table>
                                <div class="flex items-center justify-between bottom-0 absolute w-full">
                                    <div class="flex">
                                        <div class="bg-blue-500 py-0.5 px-2 text-blue-50 rounded-sm mr-2">{{station.logger}}</div>
                                        <div class="bg-orange-500 py-0.5 px-2 text-orange-50 rounded-sm">{{station.sensor}}</div>
                                    </div>
                                    <div v-if="station.status == 'connected'" class="bg-green-500 py-0.5 px-2 text-orange-50 rounded-sm">{{station.status}}</div>
                                    <div v-if="station.status == 'disconnected'" class="bg-red-500 py-0.5 px-2 text-orange-50 rounded-sm">{{station.status}}</div>
                                    <div v-if="station.status == 'maintenence'" class="bg-yellow-500 py-0.5 px-2 text-orange-50 rounded-sm">{{station.status}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-station :show="modalStation" :mode="modalMode" :segment="segment" :station="editStation" @onClose="onCloseModal" @getData="getData"></create-station>
        <modal :show="modalSegment">
            <div class=" w-2/6 bg-gray-800 rounded-sm my-8">
                <div class="px-5  py-2 mb-5 flex items-center justify-between">
                    <div>{{modalSegmentTitle}}</div>
                    <a @click.prevent="modalSegment = false" href="#" class="text-red-700 hover:text-red-800"><i class="icon-cancel-square2"></i></a>
                </div>
                <form @submit.prevent="onSubmitSegment">
                    <div class="px-5 mb-5">
                        <label for="">Nama Segmen <span class="text-red-500">*</span></label>
                        <input ref="namaSegment" v-model="form.name" type="text" class="form-control mt-3 bg-gray-700 border-gray-600 placeholder-gray-500" :class="{'border-red-500': errSegment}" placeholder="Nama sungai / kali / danau / waduk">
                        <span class="text-red-500">{{errSegment}}</span>
                    </div>
                    <div class="px-5">
                        <label for="">Lokasi <span class="text-red-500">*</span></label>
                        <div class="mt-3 flex flex-col">
                            <select-2 v-model="form.lokasi" class="form-control" :placeholder="'Pilih Lokasi'">
                                <option value=""></option>
                                <option value="Sungai">Sungai</option>
                                <option value="Waduk">Waduk</option>
                            </select-2>
                        </div>
                    </div>
                    <div class="p-5 text-right">
                        <button class="bg-green-500 h-9 px-5 text-green-100 rounded-sm relative" :disabled="isDisabled">SIMPAN
                            <div v-show="isDisabled" class="absolute bg-green-400 w-full h-full  inset-0 rounded-sm flex items-center justify-center">
                                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </modal>
        <modal :show="modalStatus">
            <div class="w-2/5 bg-gray-800 p-5 rounded-sm my-8">
                <div class="mb-5">STATUS KONFIGURASI STASIUN {{modalStatusTitle}}</div>
                <form @submit.prevent="onSubmitStatus">
                    <div class="flex items-center space-x-5 mb-5">
                        <div class="form-radio">
                            <input v-model="formStatus.status" @change="errStatus=''" id="conected" type="radio" name="status" value="connected">
                            <label for="conected">Sudah terkoneksi</label>
                        </div>
                        <div class="form-radio">
                            <input v-model="formStatus.status" @change="errStatus=''" id="maintenence" type="radio" name="status" value="maintenence">
                            <label for="maintenence">Dalam perawatan</label>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <div>
                            <div v-if="errStatus" class="text-red-500">harap pilih status</div>  
                        </div>
                        <div>
                            <button type="button" @click="modalStatus = false" class="bg-gray-600 rounded-sm h-9 px-5 text-gray-50 hover:bg-gray-700 mr-3">BATAL</button>
                            <button class="bg-blue-600 rounded-sm h-9 px-5 text-blue-50 hover:bg-blue-700">SIMPAN</button>
                        </div>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
import tooltip from '../components/tooltip.vue'
import CreateStation from '../components/createStation.vue'
import select2 from '../components/Select2.vue'
import axios from 'axios'
import modal from '../components/Modal.vue'
export default {
    components: {tooltip, CreateStation, select2, modal},
    data () {
        return {
            isDisabled: false,
            modalSegment: false,
            modalSegmentTitle: 'TAMBAH SEGMEN BARU',
            modalStatus: false,
            modalStatusTitle: '',
            search: '',
            searchStation: '',
            imgURL: process.env.VUE_APP_API,
            segments: [],
            modalStation: false,
            modalMode: '',
            segment: '',
            editStation: '',
            form: {
                id: '',
                name: '',
                lokasi: ''
            },
            errSegment: '',
            formStatus: {
                _id: '',
                status: '',
            },
            errStatus: ''
        }
    },
    mounted () {
        this.getData()
    },
    computed: {
        filterSegments () {
            return this.segments.filter(obj => {
                return obj.name.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        filterStations: function (e) {
            console.log(e);
            return 'hallo'
        }
    },
    methods: {
        getData () {
            axios.get('/segments')
            .then(res => {
                this.segments = res.data
            })
        },
        onCloseModal () {
            this.modalStation = false
            this.editStation = false
            this.modalMode = ''
        },
        onAddSegment () {
            this.modalSegmentTitle = 'TAMBAH SEGMEN BARU'
            this.modalSegment = true
            this.form.name = ''
            this.form.lokasi = ''
            this.errSegment = ''
            this.$nextTick(() => {
                this.$refs.namaSegment.focus()
            })
        },
        onEditSegment (segment) {
            this.modalSegment = true
            this.modalSegmentTitle = 'EDTI SEGMEN'
            this.form.id = segment._id
            this.form.name = segment.name
            this.form.lokasi = segment.lokasi
            this.$nextTick(() => {
                this.$refs.namaSegment.focus()
            })
        },
        onCreate (segment) {
            this.segment = segment
            this.editStation = ''
            this.modalStation = true
            this.modalMode = 'create'
        },
        onEdit (segment, station) {
            this.modalMode = 'edit'
            this.segment = segment
            this.editStation = station
            this.modalStation = true
        },
        onSubmitSegment () {
            if(!this.form.name) {
                this.errSegment = true
            }
            if(this.form.name) {
                this.isDisabled = true
                if(this.modalSegmentTitle == 'TAMBAH SEGMEN BARU') {
                    axios.post('/segments/create', this.form)
                    .then(() => {
                        this.isDisabled = false
                        this.modalSegment = false
                        this.getData()
                    })
                    .catch(() => {
                        this.isDisabled = false
                        this.errSegment = 'Nama segmen sudah tersimpan di database'
                    })
                } else {
                    axios.put('/segments/update/'+this.form.id, this.form)
                    .then(() => {
                        this.isDisabled = false
                        this.modalSegment = false
                        this.getData()
                    })
                }
            }
        },
        onSetStatus (station) {
            this.modalStatus = true
            this.modalStatusTitle = station.name
            this.formStatus._id = station._id
            this.formStatus.status = station.status
        },
        onSubmitStatus () {
            if(!this.formStatus.status) {
                this.errStatus = true
            }
            if(this.formStatus.status) {
                axios.put('/stations/status/'+this.formStatus._id, this.formStatus)
                .then(() => {
                   this.modalStatus = false
                   this.getData()
                })
            }
        }
    }
}
</script>