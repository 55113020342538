1<template>
	<div class="m-5 text-gray-400">
		<div class="flex items-center mb-5">
			<calendar @date="onSelectDate" class="mr-3"></calendar>
			<dropdown-segment @blur="onSelectSegment" class="mr-3"></dropdown-segment>
			<div v-show="segmentId">
				<dropdown-station :stations="stations" @blur="onSelectStation"></dropdown-station>
			</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="pH" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-cyan-600 top-3 text-cyan-100 rounded-sm">pH</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="DO" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-purple-600 top-3 text-cyan-100 rounded-sm">DO</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="turb" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-orange-600 top-3 text-cyan-100 rounded-sm">TURB</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="salt" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-pink-600 top-3 text-cyan-100 rounded-sm">SALT</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="cond" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-gray-600 top-3 text-cyan-100 rounded-sm">COND</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="dept" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-indigo-600 top-3 text-cyan-100 rounded-sm">DEPT</div>
		</div>
		<div class="bg-gray-800 p-5 pt-12 rounded-sm relative mb-5">
			<canvas id="temp" height="100"></canvas>
			<div class="absolute py-0.5 w-16 text-center bg-red-600 top-3 text-cyan-100 rounded-sm">TEMP</div>
		</div>
	</div>
</template>
<script>
import DropdownSegment from '../components/DropdownSegment.vue'
import DropdownStation from '../components/DropdownStation.vue'
import Calendar from '../components/calendar.vue'
import Chart from 'chart.js/auto'
import axios from 'axios'
import {moment} from '../moment'
export default {
	components: {DropdownSegment, DropdownStation, Calendar},
	data () {
		return {
			segmentId: '',
			stations: '',
			stationId: '',
			date: new Date(),
			label: 'Hari ini',
			labels: [],
			ph: [],
			dO: [],
			turb: [],
			salt: [],
			cond: [],
			dept: [],
			temp: [],
		}
	},
	mounted () {
		let app = this
		this.getData()
		const warningLine = {
			id: 'warningLine',
			beforeDraw(chart, args, options) {
				const {ctx, chartArea: {right,left, width}, scales: {y}} = chart
				ctx.save()

				ctx.strokeStyle = options.maxLineColor
				ctx.strokeRect(left, y.getPixelForValue(options.maxLineValue), width, 0)
				ctx.restore()

				ctx.font = '12px Arial'
				ctx.fillStyle = options.maxLineColor
				ctx.fillText( options.maxLineText,right - 5 , y.getPixelForValue(options.maxLineValue) - 5)
				ctx.textAlign = 'right'
				ctx.restore()

				ctx.strokeStyle = options.minLineColor
				ctx.strokeRect(left, y.getPixelForValue(options.minLineValue), width, 0)
				ctx.restore()
				ctx.font = '12px Arial'
				ctx.fillStyle = options.minLineColor
				ctx.fillText( options.minLineText,right - 5 , y.getPixelForValue(options.minLineValue) - 5)
				ctx.textAlign = 'right'
				ctx.restore()


			}
		}
		const tooltipLine = {
			id: 'tooltipLine',
			beforeDraw: chart => {
				if(chart.tooltip._active && chart.tooltip._active.length) {
					const ctx = chart.ctx
					ctx.save()
					const activePoint = chart.tooltip._active[0]
					ctx.beginPath()
					
					ctx.moveTo(activePoint.element.x, chart.chartArea.top)
					ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
					ctx.lineWidth = 1
					ctx.setLineDash([5, 3]);
					ctx.strokeStyle = 'rgb(73,84,101)'
					ctx.stroke()
					ctx.restore()
				}
			}
		}
		const scales =  {
			x: {
				labels: false,
				ticks: {
					color: 'rgb(156,163,168)',
					display: false,
					font: {
						size: 9
					}
				},
				grid: {
					borderColor: 'rgb(55,65,81)',
					display: false
				}
			},
			y: {
				grace: '10%',
				grid: {
					borderColor: 'rgb(55,65,81)',
					display: true,
				},
				ticks: {
					color: 'rgb(156,163,168)',
					font: {
						size: 10
					}
				},
			}
		}
		// PH
		const pH = document.getElementById('pH')
		window.pH = new Chart(pH, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.ph,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 0,
						maxLineText: 'Ambang batas maksimal ',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 0,
						minLineText: 'Ambang batas minimal '
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// DO
		const DO = document.getElementById('DO')
		window.Do = new Chart(DO, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.dO,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// TURB
		const turb =document.getElementById('turb')
		window.Turb = new Chart(turb, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.turb,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// SALT
		const salt =document.getElementById('salt')
		window.Salt = new Chart(salt, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.salt,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// COND
		const cond =document.getElementById('cond')
		window.Cond = new Chart(cond, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.cond,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// DEPT
		const dept =document.getElementById('dept')
		window.Dept = new Chart(dept, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.dept,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

		// TEMP
		const temp =document.getElementById('temp')
		window.Temp = new Chart(temp, {
			type: 'line',
			data: {
				labels: app.labels,
				datasets: [{
					data: app.temp,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
				}]
			},
			options: {
				responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				plugins: {
					warningLine: {
						maxLineColor: 'rgb(220,38,37, 0.5)',
						maxLineValue: 8,
						maxLineText: 'Ambang batas maksimal 7.6',
						minLineColor: 'rgb(217,119,7, 0.5)',
						minLineValue: 4.6,
						minLineText: 'Ambang batas minimal 4.6'
					},
					legend: {
						display: false
					}
				},
				scales
			},
			plugins: [warningLine, tooltipLine],
		})

	},
	methods: {
		getData () {
			axios.get('/statistics', {
				params: {
					segmentId: this.segmentId,
					stationId: this.stationId,
					date: this.date,
					label: this.label
				}
			})
			.then(res => {
				const datas = res.data.loggers
				const early = res.data.early
				window.pH.options.plugins.warningLine.maxLineValue = early.maxPh
				window.pH.options.plugins.warningLine.minLineValue = early.minPh
				window.pH.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxPh
				window.pH.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minPh
				window.Do.options.plugins.warningLine.maxLineValue = early.maxDo
				window.Do.options.plugins.warningLine.minLineValue = early.minDo
				window.Do.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxDo
				window.Do.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minDo
				window.Turb.options.plugins.warningLine.maxLineValue = early.maxTurb
				window.Turb.options.plugins.warningLine.minLineValue = early.minTurb
				window.Turb.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxTurb
				window.Turb.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minTurb
				window.Salt.options.plugins.warningLine.maxLineValue = early.maxSalt
				window.Salt.options.plugins.warningLine.minLineValue = early.minSalt
				window.Salt.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxSalt
				window.Salt.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minSalt
				window.Cond.options.plugins.warningLine.maxLineValue = early.maxCond
				window.Cond.options.plugins.warningLine.minLineValue = early.minCond
				window.Cond.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxCond
				window.Cond.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minCond
				window.Dept.options.plugins.warningLine.maxLineValue = early.maxDept
				window.Dept.options.plugins.warningLine.minLineValue = early.minDept
				window.Dept.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxDept
				window.Dept.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minDept
				window.Temp.options.plugins.warningLine.maxLineValue = early.maxTemp
				window.Temp.options.plugins.warningLine.minLineValue = early.minTemp
				window.Temp.options.plugins.warningLine.maxLineText = 'Ambang batas maksimal '+ early.maxTemp
				window.Temp.options.plugins.warningLine.minLineText = 'Ambang batas maksimal '+ early.minTemp
				for (let i = 0; i < datas.length; i++) {
					const dt = datas[i];
					this.labels.push(moment(dt.addedAt))
					this.ph.push(dt.ph.toFixed(2))	
					this.dO.push(dt.do.toFixed(2))
					this.turb.push(dt.turb.toFixed(2))
					this.salt.push(dt.salt.toFixed(2))
					this.cond.push(dt.cond.toFixed(2))
					this.dept.push(dt.dept.toFixed(2))
					this.temp.push(dt.temp.toFixed(2))
				}
				this.$nextTick(() => {
					window.pH.update()
					window.Do.update()
					window.Turb.update()
					window.Salt.update()
					window.Cond.update()
					window.Dept.update()
					window.Temp.update()
				})
			})
		},
		onSelectSegment (id, stations) {
			this.segmentId = id
			this.stations = stations
		},
		onSelectStation (id) {
			this.clearData()
			this.stationId = id
			this.$nextTick(() => {
				this.getData()
			})
		},
		onSelectDate (d, l) {
			this.clearData()
			this.date = d
			this.label = l
			this.$nextTick(() => {
				this.getData()
			})
		},
		clearData () {
			this.labels.splice(0, this.labels.length)
			this.ph.splice(0, this.ph.length)
			this.dO.splice(0, this.dO.length)
			this.turb.splice(0, this.turb.length)
			this.salt.splice(0, this.salt.length)
			this.cond.splice(0, this.cond.length)
			this.dept.splice(0, this.dept)
			this.temp.splice(0, this.temp.length)
			window.pH.update()
			window.Do.update()
			window.Turb.update()
			window.Salt.update()
			window.Cond.update()
			window.Dept.update()
			window.Temp.update()
		}
	}
}
</script>