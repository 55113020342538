<template>
    <modal :show="show">
        <div class="w-full mx-20 my-8 bg-gray-800 rounded-sm">
            <div class="flex items-center justify-between px-5 py-2.5 bg-gray-700 bg-opacity-50 rounded-t-sm">
                <div>LIVE</div>
                <div>
                    <a @click.prevent="onClose" href="#"><i class="icon-cancel-square2 hover:text-red-500"></i></a>
                </div>
            </div>
            <div class="p-5 grid grid-cols-12 gap-3">
                <div class="col-span-9">
                    <div class="grid grid-cols-12 gap-3">
                        <div class="col-span-8 border border-gray-600 rounded-sm">
                            <div class="flex items-center justify-between p-3 mb-2">
                                <div>
                                    <div class="text-xl font-medium uppercase flex items-center"><i class="icon-station mr-2" style="font-size: 20px"></i>{{value.name}}</div>
                                </div>
                                <div>
                                    <div v-if="value.status == 'connected'" class="bg-green-600 py-1 px-2 rounded-sm text-green-50">{{value.status}}</div>
                                    <div v-if="value.status == 'maintenence'" class="bg-yellow-600 py-1 px-2 rounded-sm text-yellow-50">{{value.status}}</div>
                                    <div v-if="value.status == 'disconnected'" class="bg-red-600 py-1 px-2 rounded-sm text-red-50">{{value.status}}</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-12 divide-x divide-gray-700">
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                        <div class="text-lg font-medium text-cyan-600">{{storetPh}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-cyan-600">{{indexPh}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.minPh}} - {{qualty.maxPh}}</div>
                                    </div>
                                    <div class="bg-cyan-600 text-cyan-50 py-1">pH</div>
                                </div>
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                       <div class="text-lg font-medium text-purple-600">{{storetDo}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-purple-600">{{indexDo}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.minDo}}</div>
                                    </div>
                                    <div class="bg-purple-600 text-purple-50 py-1">DO</div>
                                </div>
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                        <div class="text-lg font-medium text-orange-600">{{storetTurb}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-orange-600">--</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.maxTurb}}</div>
                                    </div>
                                    <div class="bg-orange-600 text-orange-50 py-1">TURB</div>
                                </div>
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                        <div class="text-lg font-medium text-pink-600">{{storetSalt}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-pink-600">--</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.maxSalt}}</div>
                                    </div>
                                    <div class="bg-pink-600 text-pink-50 py-1">SALT</div>
                                </div>
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                        <div class="text-lg font-medium text-gray-500">{{storetCond}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-gray-600">--</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.maxCond}}</div>
                                    </div>
                                    <div class="bg-gray-600 text-gray-50 py-1">COND</div>
                                </div>
                                <div class="col-span-2 text-center">
                                    <div class="text-xs text-center mb-2">
                                        <div>STORET</div>
                                        <div class="text-lg font-medium text-red-600">{{storetTemp}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>Ci/Li</div>
                                        <div class="text-lg font-medium text-red-600">{{indexTemp}}</div>
                                    </div>
                                    <div class="text-xs text-center mb-2">
                                        <div>BAKU MUTU</div>
                                        <div>{{qualty.minTemp}} - {{qualty.maxTemp}}</div>
                                    </div>
                                    <div class="bg-red-600 text-red-50 py-1">TEMP</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-4">
                            <div class="grid grid-cols-2 gap-2 h-full">
                                <div class="col-span-1 border border-gray-700 rounded-sm p-3 text-center flex flex-col justify-between">
                                    <div class="text-lg">STORET</div>
                                    <div class="text-3xl font-medium" :class="storetResult.color">{{storetScore}}</div>
                                    <div>{{storetResult.text}}</div>
                                </div>
                                <div class="col-span-1 border border-gray-700 rounded-sm p-3 text-center flex flex-col justify-between">
                                    <div class="text-lg">INDEKS (IP)</div>
                                    <div class="text-3xl font-medium" :class="indexResult.color">{{indexPolution}}</div>
                                    <div>{{indexResult.text}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- SENSOR -->
                    <div class="border rounded-sm border-gray-600 mt-3 divide-y divide-gray-600">
                        <div class="grid grid-cols-12 divide-x divide-gray-600">
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">pH</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minPh}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgPh  }}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxPh}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-cyan-600 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-cyan-600">{{sensor.ph}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="ph" height="110"></canvas>
                                </div>
                            </div>
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">DO</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minDo}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgDo}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxDo}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-purple-600 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-purple-600">{{sensor.do}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="do" height="110"></canvas>
                                </div>
                            </div>
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">TURB</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minTurb}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgTurb}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxTurb}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-orange-600 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-orange-600">{{sensor.turb}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="turb" height="110"></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 divide-x divide-gray-600">
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">SALT</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minSalt}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgSalt}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxSalt}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-pink-600 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-pink-600">{{sensor.salt}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="salt" height="110"></canvas>
                                </div>
                            </div>
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">COND</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minCond}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgCond}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxCond}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-gray-400 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-gray-400">{{sensor.cond}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="cond" height="110"></canvas>
                                </div>
                            </div>
                            <div class="col-span-4 bg-gray-700 bg-opacity-50">
                                <div class="grid grid-cols-2 p-3 gap-5">
                                    <div class="col-span-1">
                                        <div class="text-base font-semibold text-gray-300">TEMP</div>
                                        <div class="flex items-center justify-between text-xs">
                                            <div class="text-center">
                                                <div>min</div>
                                                <div class="text-yellow-500">{{minTemp}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>avg</div>
                                                <div class="text-green-500">{{avgTemp}}</div>
                                            </div>
                                            <div class="text-center">
                                                <div>max</div>
                                                <div class="text-red-500">{{maxTemp}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1 border rounded-sm border-red-500 px-3 flex items-center justify-end">
                                        <div v-if="sensor" class="text-2xl font-semibold text-red-500">{{sensor.temp}}</div>
                                        <div v-else class="text-2xl">--</div>
                                    </div>
                                </div>
                                <div class="w-full px-2">
                                    <canvas id="temp" height="110"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="value.status == 'connected'" class="col-span-3 border border-gray-600 rounded-sm">
                    <div class="grid grid-cols-2 gap-5 p-5">
                        <div class="border rounded-sm border-gray-600 p-5 text-center flex flex-col justify-between">
                            <div class="flex justify-center mb-2">
                                <i class="icon-spinner4 icon-4x" :class="{'animate-spin': !pumpStatus}"></i>
                            </div>
                            <div>POMPA</div>
                        </div>
                        <div class="text-center flex flex-col justify-between border rounded-sm p-5 border-gray-600">
                            <div class="flex justify-center mt-1">
                                <a @click.prevent="onPower" href="#" :class="[!powerStatus ? 'bg-gray-600 ring-green-600': 'bg-gray-700 ring-gray-500']" class="flex items-center justify-center rounded-full relative h-14 w-14 pt-1 ring-4">
                                    <div href="" class="absolute top-2.5"><i :class="{'text-green-500': !powerStatus}" class="icon-switch icon-2x"></i></div>
                                </a>
                            </div>
                            <div>POWER</div>
                        </div>
                    </div>
                    <div class="px-5">
                        <div v-if="powerStatus == 0 && pumpStatus == 1" class="bg-yellow-500 p-3 text-black text-center font-semibold rounded-sm blink">SEDANG MENGURAS AIR</div>
                        <div v-if="powerStatus == 0 && pumpStatus == 0" class="bg-green-500 p-3 text-green-50 text-center font-semibold rounded-sm">SENSOR SEDANG BERKERJA</div>
                        <div v-if="powerStatus == 1 & pumpStatus == 1" class="bg-red-500 p-3 text-red-50 text-center font-semibold rounded-sm">SISTEM SEDANG ISTIRAHAT</div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show="alert">
            <div class=" w-2/6 p-2 bg-gray-900 mt-20 rounded-sm">
                <div class="text-center mt-10 text-lg">
                    TIDAK DAPAT TERSAMBUNG KE STASIUN
                </div>
                <div class="mt-5 text-center mb-5">
                    <button @click="alert = false" class="bg-green-500 h-9 px-5 rounded-sm text-green-50">KELUAR</button>
                </div>
            </div>
        </modal>
    </modal>
</template>

<script>
import axios from 'axios'
import Modal from '../components/Modal.vue'
import Chart from 'chart.js/auto'
import { db } from '../db'
const station = db.ref('dlhJabar')
export default {
    components: {Modal},
    props:['show','value'],
    data () {
        return {
            alert: false,
            power: true,
            powerStatus: 1,
            pumpStatus: 1,
            sensor: {
                ph: 0,
                do: 0,
                turb: 0,
                salt: 0,
                cond: 0,
                temp: 0
            },
            status: '',
            phData: [],
            phTime: [],
            doData: [],
            doTime: [],
            turbData: [],
            turbTime: [],
            saltData: [],
            saltTime: [],
            condData: [],
            condTime: [],
            tempData: [],
            tempTime: [],
            qualty: ''
        }
    },
    computed: {
        storetScore () {
            return this.storetPh + this.storetDo + this.storetTemp
        },
        storetResult () {
            let res = {
                text: '',
                color: ''
            };
            if(this.storetScore == 0) {
                res.color = 'text-green-500'
                res.text = 'BAIK'
            }
            if(this.storetScore <= -1) {
                res.color = 'text-blue-500'
                res.text = 'CEMAR RINGAN'
            }
            if(this.storetScore <= -11) {
                res.color = 'text-yellow-500'
                res.text = 'CEMAR SEDANG'
            }
            if(this.storetScore < -30) {
                res.color = 'text-red-500',
                res.text = 'CEMAR BERAT'
            }
            return res
        },
        indexResult () {
            let res = {
                text: '',
                color: ''
            };
            if(0 < this.indexPolution && this.indexPolution < 1) {
                res.color = 'text-green-500'
                res.text = 'BAIK'
            }
            if(1 < this.indexPolution && this.indexPolution < 5) {
                res.color = 'text-blue-500'
                res.text = 'CEMAR RINGAN'
            }
            if(5 < this.indexPolution && this.indexPolution < 10) {
                res.color = 'text-yellow-500'
                res.text = 'CEMAR SEDANG'
            }
            if(10 < this.indexPolution) {
                res.color = 'text-red-500',
                res.text = 'CEMAR BERAT'
            }
            return res
        },
        maxPh () {
            let max = 0
            if(this.phData.length > 0) {
                max = Math.max(...this.phData)
            }
            return max.toFixed(2)
        },
        minPh () {
            let min = 0
            if(this.phData.length > 0) {
                min = Math.min(...this.phData)
            }
            return min.toFixed(2)
        },
        avgPh () {
            let count = this.phData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.phData.length; i++) {
                const el = parseFloat(this.phData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetPh () {
            let smin, smax, savg;
            let score = 0
            if(this.maxPh > 0) {
                smin =  (this.minPh > this.qualty.maxPh) || (this.minPh < this.qualty.minPh) ? -2 : 0
                smax =  (this.maxPh > this.qualty.maxPh) || (this.maxPh < this.qualty.minPh) ? -2 : 0
                savg =  (this.avgPh > this.qualty.maxPh) || (this.avgPh < this.qualty.minPh) ? -6 : 0
                score = smin + smax + savg
            }
            return score
        },
        indexPh () {
            let CiLi = 0
            if(this.avgPh > 0) {
                let Li = (this.qualty.minPh + this.qualty.maxPh) / 2
                CiLi = (this.avgPh - Li) / (this.qualty.minPh - Li)
                if(CiLi > 1) {
                    CiLi = 1 + (Math.log10(CiLi)*5)
                }
            }
            return CiLi.toFixed(2)
        },
        maxDo () {
            let max = 0
            if(this.doData.length > 0) {
                max = Math.max(...this.doData)
            }
            return max.toFixed(2)
        },
        minDo () {
            let min = 0
            if(this.doData.length > 0) {
                min = Math.min(...this.doData)
            }
            return min.toFixed(2)
        },
        avgDo () {
            let count = this.doData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.doData.length; i++) {
                const el = parseFloat(this.doData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetDo () {
            let smin, smax, savg
            let score = 0
            if(this.maxDo > 0) {
                smin =  (this.minDo < this.qualty.minDo) ? -2 : 0
                smax =  (this.maxDo < this.qualty.minDo) ? -2 : 0
                savg =  (this.avgDo < this.qualty.minDo) ? -6 : 0
                score = smin + smax + savg
            }
            return score
        },
        indexDo () {
            let CiLi = 0
            let tempDo = [
                {temp: 23, sat: 8.4},
                {temp: 24, sat: 8.3},
                {temp: 25, sat: 8.1},
                {temp: 26, sat: 8.0},
                {temp: 27, sat: 7.8},
                {temp: 28, sat: 7.9},
                {temp: 28, sat: 7.9},
                {temp: 29, sat: 7.6},
            ]
            if(this.avgDo > 0) {
                let temp = tempDo.find(obj => {
                  obj.temp == parseInt(this.avgTemp)
                  return obj.sat
                })
                CiLi = (temp.sat - this.avgDo) / (temp.sat - this.qualty.minDo)
                if(CiLi > 1) {
                    CiLi = 1 + (Math.log10(CiLi)*5)
                }
            }
            return CiLi.toFixed(2)
        },

        maxTurb () {
            let max = 0
            if(this.turbData.length > 0) {
                max = Math.max(...this.turbData)
            }
            return max.toFixed(2)
        },
        minTurb () {
            let min = 0
            if(this.turbData.length > 0) {
                min = Math.min(...this.turbData)
            }
            return min.toFixed(2)
        },
        avgTurb () {
            let count = this.turbData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.turbData.length; i++) {
                const el = parseFloat(this.turbData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetTurb () {
            let smin, smax, savg
            let score = 0
            if(this.maxTurb > 0 && this.qualty.maxTurb > 0) {
                smin =  (this.minTurb > this.qualty.maxTurb) ? -2 : 0
                smax =  (this.maxTurb > this.qualty.maxTurb) ? -2 : 0
                savg =  (this.avgTurb > this.qualty.maxTurb) ? -6 : 0
                score = smin + smax + savg
            }
            return score
        },
        maxSalt () {
            let max = 0
            if(this.saltData.length > 0) {
                max = Math.max(...this.saltData)
            }
            return max.toFixed(2)
        },
        minSalt () {
            let min = 0
            if(this.saltData.length > 0) {
                min = Math.min(...this.saltData)
            }
            return min.toFixed(2)
        },
        avgSalt () {
            let count = this.saltData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.saltData.length; i++) {
                const el = parseFloat(this.saltData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetSalt () {
            let smin, smax, savg
            let score = 0
            if(this.maxSalt > 0 && this.qualty.maxSalt > 0) {
                smin =  (this.minSalt > this.qualty.maxSalt) ? -2 : 0
                smax =  (this.maxSalt > this.qualty.maxSalt) ? -2 : 0
                savg =  (this.avgSalt > this.qualty.maxSalt) ? -6 : 0
                score = smin + smax + savg
            }
            return score
        },
        maxCond () {
            let max = 0
            if(this.condData.length > 0) {
                max = Math.max(...this.condData)
            }
            return max.toFixed(2)
        },
        minCond () {
            let min = 0
            if(this.condData.length > 0) {
                min = Math.min(...this.condData)
            }
            return min.toFixed(2)
        },
        avgCond () {
            let count = this.condData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.condData.length; i++) {
                const el = parseFloat(this.condData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetCond () {
            let smin, smax, savg
            let score = 0
            if(this.maxCond > 0 && this.qualty.maxCond > 0) {
                smin =  (this.minCond > this.qualty.maxCond) ? -2 : 0
                smax =  (this.maxCond > this.qualty.maxCond) ? -2 : 0
                savg =  (this.avgCond > this.qualty.maxCond) ? -6 : 0
                score = smin + smax + savg
            }
            return score
        },
        maxTemp () {
            let max = 0
            if(this.tempData.length > 0) {
                max = Math.max(...this.tempData)
            }
            return max.toFixed(2)
        },
        minTemp () {
            let min = 0
            if(this.tempData.length > 0) {
                min = Math.min(...this.tempData)
            }
            return min.toFixed(2)
        },
        avgTemp () {
            let count = this.tempData.length
            let total = 0; 
            let avg = 0
            for (let i = 0; i < this.tempData.length; i++) {
                const el = parseFloat(this.tempData[i]);
                total += el
            }
            if(count > 0) {
                avg = total / count
            }
            return avg.toFixed(2)
        },
        storetTemp () {
            let smin, smax, savg
            let score = 0
            if(this.maxTemp > 0) {
                smin =  (this.minTemp > this.qualty.maxTemp) || (this.minTemp < this.qualty.minTemp) ? -1 : 0
                smax =  (this.maxTemp > this.qualty.maxTemp) || (this.maxTemp < this.qualty.minTemp) ? -1 : 0
                savg =  (this.avgTemp > this.qualty.maxTemp) || (this.avgTemp < this.qualty.minTemp) ? -3 : 0
                score = smin + smax + savg
            }
            return score
        },
        indexTemp () {
            let CiLi = 0
            if(this.avgTemp > 0) {
                let Li = (this.qualty.minTemp + this.qualty.maxTemp) / 2
                CiLi = (this.avgTemp - Li) / (this.qualty.minTemp - Li)
                if(CiLi > 1) {
                    CiLi = 1 + (Math.log10(CiLi)*5)
                }
            }
            return CiLi.toFixed(2)
        },
        indexPolution () {
            let CiLi = [this.indexPh, this.indexDo, this.indexTemp]
            let max = Math.max(...CiLi)
            let avg = parseFloat(this.indexPh + this.indexDo + this.indexTemp) / 3
            let ip = Math.sqrt((max**2 + avg**2) / 2).toFixed(2)
            return ip
        }
    },
    mounted () {
        this.getData()
        let app = this
        const tooltipLine = {
			id: 'tooltipLine',
			beforeDraw: chart => {
				if(chart.tooltip._active && chart.tooltip._active.length) {
					const ctx = chart.ctx
					ctx.save()
					const activePoint = chart.tooltip._active[0]
					ctx.beginPath()
					ctx.setLineDash([5, 3])
					ctx.moveTo(activePoint.element.x, chart.chartArea.top)
					ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
					ctx.lineWidth = 1
					ctx.strokeStyle = 'rgb(130,141,159)'
					ctx.stroke()
					ctx.restore()
				}
			}
		}

        var ctxPh = document.getElementById('ph');
        window.myPh = new Chart(ctxPh, {
            type: 'line',
            data: {
                labels: app.phTime,
                datasets: [{
					data: app.phData,
					borderColor: 'rgb(5,182,212, 0.7)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })
        
        var ctxDo = document.getElementById('do');
        window.myDo = new Chart(ctxDo, {
            type: 'line',
            data: {
                labels: app.doTime,
                datasets: [{
					data: app.doData,
					borderColor: 'rgb(147,51,233)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })

        var ctxTurb = document.getElementById('turb');
        window.myTurb = new Chart(ctxTurb, {
            type: 'line',
            data: {
                labels: app.turbTime,
                datasets: [{
					data: app.turbData,
					borderColor: 'rgb(234,88,11)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })

        var ctxSalt = document.getElementById('salt');
        window.mySalt = new Chart(ctxSalt, {
            type: 'line',
            data: {
                labels: app.saltTime,
                datasets: [{
					data: app.saltData,
					borderColor: 'rgb(218,40,119)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })

        var ctxCond = document.getElementById('cond');
        window.myCond = new Chart(ctxCond, {
            type: 'line',
            data: {
                labels: app.condTime,
                datasets: [{
					data: app.condData,
					borderColor: 'rgb(118,127,141)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })

        var ctxTemp = document.getElementById('temp');
        window.myTemp = new Chart(ctxTemp, {
            type: 'line',
            data: {
                labels: app.tempTime,
                datasets: [{
					data: app.tempData,
					borderColor: 'rgb(220,38,37)',
					borderWidth: '1',
					pointRadius: '0',
					pointHoverRadius: '0',
					spanGaps: true
                }],
            },
            options: {
                responsive: true,
				interaction: {
					intersect: false,
					mode: 'index',
				},
                plugins: {
					legend: {
						display: false
					}
				},
                scales: {
					x: {
						display: false
					},
					y: {
						ticks: {
							color: 'rgb(156,163,168)',
							font: {
								size: 10
							}
						},
						grid: {
							color: 'rgb(57,66,81)',
							display: true
						}
					}
				}
            },
            plugins: [tooltipLine]
        })
    },
    methods: {
        getData () {
            axios.get('/earlyWarning')
            .then(res => {
                this.qualty = res.data
            })
        },
        onClose () {
            let minute = new Date().getMinutes()
            this.$emit('onClose')
            this.power = true
            this.powerStatus = 1
            this.pumpStatus = 1
            this.phData = []
            this.phTime = []
            window.myPh.data.labels = this.phTime
            window.myPh.data.datasets.forEach((dataset) => {
                dataset.data = this.phData
            })
            window.myPh.update()

            this.doData = []
            this.doTime = []
            window.myDo.data.labels = this.doTime
            window.myDo.data.datasets.forEach((dataset) => {
                dataset.data = this.doData
            })
            window.myDo.update()

            this.turbData = []
            this.turbTime = []
            window.myTurb.data.labels = this.turbTime
            window.myTurb.data.datasets.forEach((dataset) => {
                dataset.data = this.turbData
            })
            window.myTurb.update()
            
            this.saltData = []
            this.saltTime = []
            window.mySalt.data.labels = this.saltTime
            window.mySalt.data.datasets.forEach((dataset) => {
                dataset.data = this.saltData
            })
            window.mySalt.update()

            this.condData = []
            this.condTime = []
            window.myCond.data.labels = this.condTime
            window.myCond.data.datasets.forEach((dataset) => {
                dataset.data = this.condData
            })
            window.myCond.update()

            this.tempData = []
            this.tempTime = []
            window.myTemp.data.labels = this.tempTime
            window.myTemp.data.datasets.forEach((dataset) => {
                dataset.data = this.tempData
            })
            window.myTemp.update()
            if(minute < 57) {
                axios.post('/remote/power-pump', {
                    stationId: this.value.stationId,
                    power: 1
                })
                .then(res => {
                    this.powerStatus = res.data
                })
            }
           
        },
        onPower () {
            this.power = !this.power
            axios.post('/remote/power-pump', {
                stationId: this.value.stationId,
                power: this.power ? 1:0
            })
            .then(res => {
                this.powerStatus = res.data
                // setTimeout(() => {
                //     if(this.powerStatus == 0 && this.pumpStatus == 1) {
                //         this.powerStatus = 1
                //         this.power = true
                //         this.alert = true
                //     }
                // }, 50000)
            })
        },
        checkTime(tm) {
            let date = new Date(tm)
            let H = date.getHours()
            let m = date.getMinutes()
            let s = date.getSeconds()
            H = checkTime(H)
            m = checkTime(m)
            s = checkTime(s)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return `${H}:${m}:${s}`
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.$rtdbBind('status', station.child(value.stationId+'/status'))
            }
        },
        status (val) {
            if(val.pump == 0) {
                this.pumpStatus = val.pump
                setTimeout(() => {
                    this.$rtdbBind('sensor', station.child(this.value.stationId+'/modbus'))
                }, 20000)
            }
            if(val.pump == 1) {
                this.pumpStatus = val.pump
            }
        },
        'sensor.ph': function (val) {
            if(this.sensor.ph) {
                this.phData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.phTime.push(time)
            }
            this.$nextTick(() => {
                window.myPh.update()
            })
        },
        'sensor.do': function (val) {
            if(this.sensor.do) {
                this.doData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.doTime.push(time)
            }
            this.$nextTick(() => {
                window.myDo.update()
            })
        },
        'sensor.turb': function (val) {
            if(this.sensor.turb) {
                this.turbData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.turbTime.push(time)
            }
            this.$nextTick(() => {
                window.myTurb.update()
            })
        },
        'sensor.salt': function (val) {
            if(this.sensor.salt) {
                this.saltData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.saltTime.push(time)
            }
            this.$nextTick(() => {
                window.mySalt.update()
            })
        },
        'sensor.cond': function (val) {
            if(this.sensor.cond) {
                this.condData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.condTime.push(time)
            }
            this.$nextTick(() => {
                window.myCond.update()
            })
        },
        'sensor.temp': function (val) {
            if(this.sensor.temp) {
                this.tempData.push(val)
                let time = this.checkTime(this.sensor.addedAt)
                this.tempTime.push(time)
            }
            this.$nextTick(() => {
                window.myTemp.update()
            })
        },
    },
}
</script>

<style>
      .blink {
        animation: blink-animation 1s steps(5, start) infinite;
        -webkit-animation: blink-animation 1s steps(5, start) infinite;
      }
      @keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
      @-webkit-keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
</style>