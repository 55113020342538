import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard.vue'
import Streaming from '../views/streaming.vue'
import Segmen from '../views/segmen.vue'
import Statistics from '../views/statistics.vue'
import Warning from '../views/warning.vue'
import Report from '../views/report.vue'
import Login from '../views/login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/streaming',
    name: 'Streaming',
    component: Streaming,
    meta: {
      auth: true
    }
  },
  {
    path: '/segment',
    name: 'Segmen',
    component: Segmen,
    meta: {
      auth: true
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      auth: true
    }
  },
  {
    path: '/warning',
    name: 'Warning',
    component: Warning,
    meta: {
      auth: true
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      auth: true
    }
  },
  {
		path: '/login',
		component: Login,
		name: 'Login'
	},
]

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	const tokenLocalStorage = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	if(to.meta.auth && !store.getters['auth/token'] || to.meta.auth && !tokenLocalStorage || to.meta.auth && !userId) next({name: 'Login'})
	else if(!to.meta.auth && store.getters['auth/token'] && tokenLocalStorage && userId) next({name: 'Dashboard'})
	else next()
})

export default router
