<template>
    <div class="relative">
        <button @click.prevent="onShow" type="button" class="bg-gray-700 border border-gray-600 text-gray-300 relative rounded-sm h-9 focus:outline-none w-52">
            <div class="flex items-center h-full relative">
                <div class="bg-gray-800 bg-opacity-50 h-full w-9 rounded-l-sm flex justify-center items-center">
                    <i class="icon-station"></i>
                </div>
                <div class="px-3 opacity-75 truncate w-40 text-left">
                    {{text}}
                </div>
            </div>
            <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-gray-400"></div>
        </button>
        <div v-show="show" class="absolute min-w-max z-20 w-52 bg-gray-700 border rounded-sm border-gray-600 left-0 mt-1 text-gray-400 shadow-lg">
            <div class="px-3 py-2">
                <input ref="search" v-model="search" @keydown.down.prevent="arrowDown" @keydown.up.prevent="arrowUp" @keydown.enter.prevent="onEnter" type="text" class="h-7 w-full border border-gray-500 bg-gray-600 rounded-sm px-3 focus:outline-none" placeholder="cari nama segmen">
            </div>
            <ul ref="dropdown" class=" max-h-96 overflow-x-scroll">
                <li class="border-b border-gray-500">
                    <a @click.prevent="allStation" href="" class="block py-2 px-3 hover:bg-gray-800 hover:bg-opacity-25">Semua stasiun</a>
                </li>
                <li v-for="(station, i) in filterStations" :key="i">
                    <a :ref="'list'+i" @click.prevent="onSelect(i)" @mouseenter="onmouseEnter(i)" href="" class="block py-2 px-3" :class="{'bg-gray-800 bg-opacity-25': i == rowCount}">{{station.name}}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: ['stations'],
    data () {
        return {
            show: false,
            search: '',
            text: 'Semua stasiun',
            rowCount: 0,
        }
    },
    mounted () {
        document.body.addEventListener('click', this.closeMenu)
    },
    destroyed () {
        document.body.removeEventListener('click', this.closeMenu)
    },
    computed: {
        filterStations () {
            let sts = []
            if(this.stations) {
                sts = this.stations
            }
            return sts.filter(obj => {
                return obj.name.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    methods: {
        onShow () {
            this.show = !this.show
            if(this.show == true) {
                this.$nextTick (() => {
                    this.$refs.search.focus()
                })
            }
        },
        allStation () {
            this.search = ''
            this.text = 'Semua stasiun'
            this.$emit('blur', '')
            this.show = false
        },
        onSelect (i) {
            const stasiun = this.stations[i]
            this.text = stasiun.name
            this.$emit('blur', stasiun.stationId, stasiun.name)
            this.show = false
        },
        onEnter () {
            const stasiun = this.stations[this.rowCount]
            if(stasiun) {
                this.text = stasiun.name
                this.$emit('blur', stasiun.stationId)
                this.show = false
            }
        },
        onmouseEnter (i) {
            this.rowCount = i
        },
        arrowDown () {
            if(this.rowCount < this.stations.length -1) {
                const menuHeight = this.$refs.dropdown.clientHeight
                const scrollTop = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount +1
                this.$nextTick(() => {
                    const top = this.$refs['list'+this.rowCount][0].offsetTop
                    const height = this.$refs['list'+this.rowCount][0].getBoundingClientRect()
                    if((top+height.height) > (menuHeight + scrollTop)) {
                        this.$refs.dropdown.scrollTop = (top + height.height) - menuHeight
                    }
                })
            }
        },
        arrowUp () {
            if(this.rowCount > 0) {
                const menuHeight = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount -1
                this.$nextTick(() => {
                    const top = this.$refs['list'+this.rowCount][0].offsetTop - 83
                    if(top < menuHeight) {
                        this.$refs.dropdown.scrollTop = top
                    }
                })
            }
        },
        closeMenu ($event) {
            if(!$event || !this.$el.contains($event.target)) {
                this.show = false
            }
        }
    },
    watch: {
        stations () {
            this.allStation()
        }
    }
}
</script>