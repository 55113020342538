import axios from 'axios'
import router from '../router'
export default {
    namespaced: true,
    state: {
        token: null,
        refreshToken: null,
        user: null,
    },
    getters: {
        token (state) {
            return state.token;
        },
        user (state) {
            return state.user
        },
    },
    mutations: {
        SET_TOKEN (state, value) {
            state.token = value;
        },
        SET_REFRESH_TOKEN (state, value) {
            state.refreshToken = value;
        },
        SET_USER (state, value) {
            state.user = value;
        }
    },
    actions: {
        async login({commit}, credential) {
            await axios.post('/auth/login', credential)
            .then(res => {
                commit('SET_TOKEN', res.data.accessToken);
                commit('SET_REFRESH_TOKEN', res.data.refreshToken);
                commit('SET_USER', res.data.user);
            })
        },
        attempt ({commit}, token) {
           if(token) {
               commit('SET_TOKEN', token);
           }
        },
        async refresh_token({commit}, token) {
            await axios.post('/auth/refresh-token', {
                token: token
            })
            .then(res => {
                commit('SET_TOKEN', res.data.accessToken)
            })
            .catch(() => {
                commit('SET_TOKEN', null)
                commit('SET_REFRESH_TOKEN', null)
                commit('SET_USER', null)
                router.push('/login')
            })
        },
        async me({commit}) {
            await axios.get('/auth/me')
            .then(res => {
                commit('SET_USER', res.data)
            })
        },
        async logout({commit}, token) {
            try {
                await axios.delete('/auth/logout', {
                    data: {
                        token: token
                    }
                })
                .then(() => {
                    commit('SET_TOKEN', null)
                    commit('SET_REFRESH_TOKEN', null)
                    commit('SET_USER', null)
                })
            } catch (err) {
                commit('SET_TOKEN', null)
                commit('SET_REFRESH_TOKEN', null)
                commit('SET_USER', null)
                
            }
        }
    }
}