export function moment (d) {
    const date = new Date(d)
    let dd = date.getDate()
    let mm = date.getMonth() + 1
    let yy = date.getFullYear()
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()
    dd =checkTime(dd)
    mm = checkTime(mm)
    h = checkTime(h)
    m = checkTime(m)
    s = checkTime(s)

    function checkTime (i) {
        if(i < 10) {
            i =`0${i}`
        }
        return i
    }
    return `${dd}-${mm}-${yy} ${h}:${m}:${s}`
}