<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-30 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity backdrop-filter backdrop-blur-sm" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <div class=" w-full mx-10 rounded-sm">
                                <div class="flex items-center justify-between px-5 py-2 bg-gray-800">
                                    <div class="uppercase">{{modalTitle}}</div>
                                    <div>
                                        <a @click.prevent="onClose" href="#" class="text-red-600 hover:text-red-700"><i class="icon-cancel-square2"></i></a>
                                    </div>
                                </div>
                                <!-- body -->
                                <form @submit.prevent="onSubmit">
                                    <div class="bg-gray-900">
                                        <div class="grid grid-cols-12 mb-5">
                                            <div class="col-span-9 p-5">
                                                <!-- STATION INFO -->
                                                <div class="grid grid-cols-12 gap-5 mb-5">
                                                    <div class="col-span-5 relative flex justify-center">
                                                        <div v-show="image" ref="croppie"></div>
                                                        <div v-show="image" class="absolute z-50 bottom-1">
                                                            <a href="#" class="bg-gray-900 border-2 rounded-full px-2 py-1 text-white hover:text-gray-500 hover:bg-white hover:border-white">
                                                                Ganti foto
                                                                <input ref="imageChange" @change="onFileChange" class="h-full w-full absolute inset-0 border opacity-0" type="file" accept=".jpeg, .jpg, .png">
                                                            </a>
                                                        </div>
                                                        <div v-show="!image" class="relative w-full h-56">
                                                            <div :class="{'border-red-500': err.image}" class="border-2 border-dashed border-gray-700 rounded-sm w-full h-full">
                                                                <div v-if="!foto" class="flex w-full h-full items-center justify-center">
                                                                    <div class="text-gray-300">
                                                                        Upload foto stasiun
                                                                    </div>
                                                                </div>
                                                                <img class="m-auto" :src="foto" width="350" alt="">
                                                            </div>
                                                            <div class="absolute flex items-center justify-center bg-black top-0 bottom-0 left-0 right-0 w-full transition duration-200 rounded-sm opacity-0 bg-opacity-0 hover:bg-opacity-80 hover:opacity-100">
                                                                <a href="#" class="bg-transparent border-2 rounded-full px-2 py-1 text-white hover:text-gray-500 hover:bg-white hover:border-white relative">
                                                                    Pilih foto
                                                                    <input ref="imageInput" @change="onFileChange" class="h-full w-full absolute inset-0 border opacity-0" type="file" accept=".jpeg, .jpg, .png">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-7">
                                                        <legend class="flex items-center pb-2 border-b leading-none border-gray-700 mb-5"><i class="icon-station mr-3"></i> INFORMASI STASIUN</legend>
                                                        <div class="grid grid-cols-5 gap-5 mb-5">
                                                            <div class="col-span-2">
                                                                <label for="">Stasiun ID <span>*</span></label>
                                                                <input v-model="form.stationId" :class="{'border-red-500': err.stationId}" @keydown="err.stationId=''" type="text" class="form-control mt-2 bg-gray-800 border-gray-700">
                                                            </div>
                                                            <div class="col-span-3">
                                                                <label for="">Nama stasiun *</label>
                                                                <input v-model="form.name" :class="{'border-red-500' : err.name}" @keydown="err.name=''" type="text" class="form-control mt-2 bg-gray-800 border-gray-700">
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label for="">Alamat *</label>
                                                            <textarea v-model="form.address" rows="2" :class="{'border-red-500': err.address}" @keydown="err.address=''" class="w-full px-3 py-2 mt-2 rounded-sm border border-gray-700 focus:outline-none bg-gray-800 h-full"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- MAP -->
                                                <div class="grid grid-cols-12 gap-5 h-96">
                                                    <div class="col-span-9 rounded-sm border relative">
                                                        <div id="map" class="w-full h-full"></div>
                                                    </div>
                                                    <div class="col-span-3">
                                                        <div class="mb-5 border-b border-gray-700 pb-3"><i class="icon-section mr-3"></i>SEGMENTASI</div>
                                                        <div class="flex flex-col create">
                                                            <select-2 v-model="form.segmentation" :placeholder="'Pilih segmentasi'" class="bg-gray-800">
                                                                <option value=""></option>
                                                                <option value="Hulu">Hulu</option>
                                                                <option value="Tengah">Tengah</option>
                                                                <option value="Hilir">Hilir</option>
                                                            </select-2>
                                                        </div>
                                                        <div class="mb-5 border-b border-gray-700 pb-3 mt-10"><i class="icon-location4 mr-3"></i>KOORDINAT</div>
                                                        <div class="mb-5">
                                                            <label for="">Longitude *</label>
                                                            <input :class="{'border-red-500': err.coordinate}"  type="text" class="form-control mt-2 bg-gray-800 border-gray-700 cursor-not-allowed" readonly v-model="coordinate[0]">
                                                        </div>
                                                        <div class="mb-5">
                                                            <label for="">Latitude *</label>
                                                            <input :class="{'border-red-500': err.coordinate}"  type="text" class="form-control mt-2 bg-gray-800 border-gray-700 cursor-not-allowed" readonly v-model="coordinate[1]">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- HARDWARE -->
                                            <div class="col-span-3 flex flex-col justify-between border-l border-gray-700 p-5">
                                                <div>
                                                    <legend class="flex items-center pb-2 border-b leading-none border-gray-700 mb-5"><i class="icon-chip mr-3"></i> INFORMASI HARDWARE</legend>
                                                    <div class="mb-5">
                                                        <label for="">Data logger</label>
                                                        <input v-model="form.logger" type="text" class="form-control bg-gray-800 border-gray-700 mt-2">
                                                    </div>
                                                    <div class="mb-5">
                                                        <label for="">Sensor</label>
                                                        <input v-model="form.sensor" type="text" class="form-control bg-gray-800 border-gray-700 mt-2">
                                                    </div>
                                                    <div class="mb-5">
                                                        <label for="">IDPEL (PLN kwh)</label>
                                                        <input v-model="form.idpel" type="text" class="form-control bg-gray-800 border-gray-700 mt-2">
                                                    </div>
                                                    <div class="mb-5">
                                                        <label for="">SIM CARD (Nomor internet)</label>
                                                        <input v-model="form.sim" type="text" class="form-control bg-gray-800 border-gray-700 mt-2">
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <button type="submit" class="bg-blue-500 h-9 px-5 rounded-sm text-blue-50 w-full relative" :disabled="isDisabled">
                                                        SIMPAN
                                                        <div v-show="isDisabled" class="absolute bg-blue-400 w-full h-full  inset-0 rounded-sm flex items-center justify-center">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </div>
                                                    </button>
                                                    <div class="mt-5">
                                                        <ul class="text-gray-500 list-disc ml-3">
                                                            <li>
                                                                <p class="text-xs"><span class="text-red-500">*</span> harus diisi, tidak boleh kosong</p>
                                                            </li>
                                                            <li>
                                                                <p class="text-xs">Foto / gambar harus bertipe .jpeg, .jpg, .png </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import Croppie from 'croppie'
import 'croppie/croppie.css'
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Select2 from '../components/Select2.vue'
import axios from 'axios'
export default {
    components: {Select2},
    props: ['show','segment', 'station', 'mode'],
    data () {
        return {
            modalTitle: '',
            isDisabled: false,
            foto: "",
            map: "",
            canvas: '',
            croppie: '',
            image: '',
            coordinate: [],
            form: {
                id: '',
                segmentId: '',
                stationId: '',
                name: '',
                address: '',
                segmentation: '',
                coordinate: [],
                logger: '',
                sensor: '',
                idpel: '',
                sim: '',
                image: ''
            },
            err: {
                stationId: '',
                name: '',
                address: '',
                coordinate: '',
                image: ''
            }
        }
    },
    mounted () {
        this.initCroppie()
        mapboxgl.accessToken= "pk.eyJ1IjoibmVuZGkiLCJhIjoiY2t4cTQweHg3NzEwaDJvbXVud2RsbWxwNiJ9.C080KdMXt4y_4oSjHaMnmw"
		this.map = new mapboxgl.Map({
			container: 'map', // container ID
			style: 'mapbox://styles/mapbox/streets-v11', // style URL
			center: [107.6028079, -6.919980216], // starting position [lng, lat]
			zoom: 7, // starting zoom
            minZoom: 7,
			attributionControl: false
		});
        this.canvas = this.map.getCanvasContainer();
        // geocooder serachbox
		this.map.addControl(new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl
		}))

        // add maker onclick
		this.map.on('click', (e) => {
			if(this.marker) {
				this.marker.remove()
			}
			
			this.coordinate = [e.lngLat.lng, e.lngLat.lat]
			this.marker = new mapboxgl.Marker({
				draggable: true
			})
			.setLngLat(this.coordinate)
			.addTo(this.map)
            const markerEl = this.marker.getElement()
            markerEl.addEventListener('mouseenter', () => {
                this.canvas.style.cursor = 'move'
            })
            markerEl.addEventListener('mouseleave', () => {
                this.canvas.style.cursor = ''
            })
			this.marker.on('dragend',this.onDrag)
		})
    },
    methods: {
        onClose () {
            this.coordinate = []
            this.foto = ''
            this.image = ''
            this.$refs.imageInput.value = ''
            this.$refs.imageChange.value = ''
            this.form = {
                id: '',
                segmentId: '',
                stationId: '',
                name: '',
                address: '',
                segmentation: '',
                coordinate: [],
                logger: '',
                sensor: '',
                image: '',
                idpel: '',
                sim: ''
            },
            this.err = {
                stationId: '',
                name: '',
                address: '',
                coordinate: '',
                image: ''
            }
            this.$emit('onClose')
        },
        // mapbox on drag marker
        onDrag () {
			const lngLat = this.marker.getLngLat()
            this.coordinate = [lngLat.lng, lngLat.lat]
		},

        // initial croppie
        initCroppie () {
            let el = this.$refs.croppie
            let opt = {
                viewport: {
                    width: 320,
                    height: 200,
                },
                boundary: {
                    width: '100%',
                    height: '100%'
                },
                showZoomer: false
            }
            this.croppie = new Croppie(el, opt)
        },
        onFileChange (e) {
            const file = e.target.files[0]
            if(file) {
                this.image = URL.createObjectURL(file)
                this.err.image = ''
            }
            this.croppie.bind(this.image)
        },

        async onSubmit () {
            if(!this.form.stationId) {
                this.err.stationId = true
            }
            if(!this.form.name) {
                this.err.name = true
            }
            if(!this.form.address) {
                this.err.address = true
            }
            if(!this.image && this.mode == 'create') {
                this.err.image = true
            }
            if(this.coordinate.length < 1) {
                this.err.coordinate = true
            }
            if(this.form.stationId && this.form.name && this.form.address && this.coordinate.length > 0 ) {
                if(this.mode == 'create' && this.image) {
                    this.isDisabled = true
                    const image = await this.croppie.result('blob', {format: 'png'})
                    const formData = new FormData()
                    formData.append('image', image)
                    formData.append('segmentId', this.form.segmentId)
                    formData.append('stationId', this.form.stationId)
                    formData.append('name', this.form.name)
                    formData.append('address', this.form.address)
                    formData.append('logger', this.form.logger)
                    formData.append('sensor', this.form.sensor)
                    formData.append('segmentation', this.form.segmentation)
                    formData.append('idpel', this.form.idpel)
                    formData.append('sim', this.form.sim)
                    formData.append('coordinates', JSON.stringify(this.coordinate))
                    axios.post('/stations/create', formData)
                    .then(() => {
                        this.isDisabled = false
                        this.onClose()
                        this.$emit('getData')
                    })
                    .catch(() => {
                        this.isDisabled = false
                        this.err.stationId = true
                    })
                }
                if(this.mode == 'edit') {
                    this.isDisabled = true
                    const formData = new FormData()
                    if(this.image) {
                        const image = await this.croppie.result('blob', {format: 'png'})
                        formData.append('image', image)
                    }
                    formData.append('stationId', this.form.stationId)
                    formData.append('name', this.form.name)
                    formData.append('address', this.form.address)
                    formData.append('logger', this.form.logger)
                    formData.append('segmentation', this.form.segmentation)
                    formData.append('sensor', this.form.sensor)
                    formData.append('idpel', this.form.idpel)
                    formData.append('sim', this.form.sim)
                    formData.append('coordinates', JSON.stringify(this.coordinate))
                    axios.put('/stations/update/'+this.form.id, formData)
                    .then(() => {
                        this.isDisabled = false
                        this.$emit('getData')
                        this.onClose()
                    })
                    .catch(() => {
                        this.isDisabled = false
                        this.err.stationId = true
                    })
                }

            }
        }
    },
    watch: {
        coordinate () {
            this.err.coordinate = ''
        },
        show () {
            this.$nextTick(() => {
                this.map.resize()
            })
        },
        mode (val) {
            this.form.segmentId = this.segment._id
            if(val == 'create') {
                this.modalTitle = 'TAMBAH STASIUN '+this.segment.name
                if(this.marker) {
                    this.marker.remove()
                }
                this.map.setCenter([107.6028079, -6.919980216])
                this.map.setZoom(7)
            } 
            if(val == 'edit') {
                this.modalTitle = 'EDIT STASIUN '+this.segment.name
            }
        },
        station (val) {
            if(val) {
                this.image = ''
                this.foto = process.env.VUE_APP_API+'/'+val.image+'?'+Date.now()
                this.form.id = val._id
                this.form.stationId = val.stationId
                this.form.name = val.name
                this.form.address = val.address
                this.form.logger = val.logger
                this.form.sensor = val.sensor
                this.form.segmentation = val.segmentation
                this.form.idpel = val.idpel
                this.form.sim = val.sim
                this.coordinate = val.coordinates
                if(this.marker) {
                    this.marker.remove()
                }
                this.marker = new mapboxgl.Marker({
                    draggable: true
                })
                .setLngLat(this.coordinate)
                .addTo(this.map)
                const markerEl = this.marker.getElement()
                markerEl.addEventListener('mouseenter', () => {
                    this.canvas.style.cursor = 'move'
                })
                markerEl.addEventListener('mouseleave', () => {
                    this.canvas.style.cursor = ''
                })
                this.marker.on('dragend',this.onDrag)
                this.map.setCenter(this.coordinate)
                this.map.setZoom(15)
            }
        }
    },
    destroyed () {
        this.map.remove()
    }
}
</script>
<style scoped>
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
        display: none;
    }
    input[type=file] {
        cursor: pointer;
    }
</style>

<style>
.basemap {
  width: 100%;
  height: 100%;
}
a.mapboxgl-ctrl-logo {
	display: none !important;
}
.croppie-container .cr-slider-wrap {
    width: 75%;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    text-align: center;
}
.create .select2-selection--single:not([class*=bg-]) {
    background-color: #1f2937 !important;
}
</style>