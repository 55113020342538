<template>
    <div class="fixed flex flex-wrap justify-between px-5 items-stretch bg-gray-900 z-10 inset-x-0 text-gray-300">
		<div class="-ml-5 mr-5 text-gray-50 flex items-center border-b" style="border-color: #293341; background-color: #1b2430">
			<div class="ml-5 py-2.5 w-60">
				<div class="flex items-center">
					<div class="text-gray-400 text-lg leading-none font-medium">ONLIMO</div>
				</div>
			</div>
		</div>
		<div class="flex flex-grow items-center px-5 border-b border-gray-800 -mx-5">
			<ul class="flex felx-row">
				<li>
					<div class="flex items-center h-12">
						
					</div>
				</li>
			</ul>
			<ul class="flex felx-row items-center ml-auto">
				<li v-if="user" ref="notification" class="relative h-12 flex items-center justify-center mr-3">
					<a @click.prevent="onClickNotification" href="#" :class="{'bg-gray-500': show}" class="rounded-sm px-2 py-px relative hover:bg-gray-500">
						<i class="icon-bell2"></i>
						<div v-if="totalNotification" class="absolute bg-red-500 min-w-min h-4 ring-4 ring-gray-900 -top-2 left-4 w-4 rounded-full p-1 flex items-center justify-center font-semibold text-red-50" style="font-size: 11px">{{totalNotification}}</div>
					</a>
					<div v-show="show" class="absolute bg-gray-600 w-80 top-12 mt-0.5 rounded-sm overflow-hidden">
						<div class="p-4 shadow-lg border-gray-500">Notification</div>
						<div>
							<ul class="grid grid-cols-2 relative border-gray-500">
								<li @click="tabMenu($event, 'warning')" class="flex justify-center py-2.5 cursor-pointer border-b border-gray-500 relative">
									<div class="flex items-center" :class="{'text-green-500': tab=='warning'}">
										<span>Warning</span>
										<div class="flex items-center ml-2">
											<div>({{warningCount}})</div>
											<div class="h-2 w-2 bg-red-500 rounded-full ml-2"></div>
										</div>
									</div>
									<div v-show="tab=='warning'" class="absolute w-40 border-b-2 border-green-500 bottom-0"></div>
								</li>
								<li @click="tabMenu($event, 'aktivitas')" class="flex justify-center py-2.5 cursor-pointer border-b border-gray-500">
									<div class="flex items-center" :class="{'text-green-500': tab=='aktivitas'}">
										<span>Aktivitas</span>
										<div v-if="activityCount" class="flex items-center ml-2">
											<div>({{activityCount}})</div>
											<div class="h-2 w-2 bg-red-500 rounded-full ml-2"></div>
										</div>
									</div>
									<div v-show="tab=='aktivitas'" class="absolute w-40 border-b-2 border-green-500 bottom-0"></div>
								</li>
							</ul>
							<div ref="notif" class="max-h-96 overflow-y-scroll">
								<ul v-if="isLoading" class="divide-y divide-gray-500">
									<li v-for="i in 3" :key="i">
										<div class="px-4 py-2">
											<div class="flex">
												<div>
													<div class="bg-gray-500 h-4 w-4 rounded-sm ph-item"></div>
												</div>
												<div class="w-full ml-3">
													<div class="flex items-center justify-between w-full">
														<div class=" w-20 h-4 bg-gray-500 ph-item"></div>
														<div class=" w-28 h-4 bg-gray-500 ph-item"></div>
													</div>
													<div class="w-20 h-4 bg-gray-500 mt-2 ph-item"></div>
													<div class="w-full h-3 bg-gray-500 mt-2 ph-item"></div>
												</div>
											</div>
										</div>
									</li>
								</ul>
								<ul v-if="!isLoading" class="divide-y divide-gray-500">
									<li v-for="(notif, i) in notifications" :key="i">
										<div v-if="notif.type == 'warning'" class="px-4 py-2">
											<div class="flex">
												<div>
													<i v-if="notif.content.threshold == 'max'" class="icon-warning22 text-red-400"></i>
													<i v-if="notif.content.threshold == 'min'" class="icon-warning22 text-yellow-400"></i>
												</div>
												<div class="w-full ml-3">
													<div class="flex items-center justify-between w-full">
														<div>{{notif.content.sensor}}: {{notif.content.value}}</div>
														<div class="text-xs text-cyan-500">{{getDate(notif.createdAt)}}</div>
													</div>
													<div>{{notif.content.name}}</div>
													<div class="text-xs text-gray-400">{{notif.content.description}}</div>
												</div>
											</div>
										</div>
										<div v-if="notif.type == 'activity'" class="px-4 py-2">
											<div class="flex">
												<div>
													<i v-if="notif.content.event == 'disconnected'" class="icon-blocked text-red-400"></i>
													<i v-if="notif.content.event == 'connected'" class="icon-connection text-green-400"></i>
												</div>
												<div class="w-full ml-3">
													<div class="flex items-center justify-between w-full">
														<div>{{notif.content.event}}</div>
														<div class="text-xs text-cyan-500">{{getDate(notif.createdAt)}}</div>
													</div>
													<div>{{notif.content.name}}</div>
													<div class="text-xs text-gray-400">{{notif.content.message}}</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="px-5 py-2.5 border-t border-gray-500 text-center">
							<div v-if="notifications.length > 0">
								<a @click="onDeleteNotification" href="#" class="flex items-center justify-center hover:text-gray-100"><i class="icon-trash mr-3"></i>Bersihkan notifikasi</a>
							</div>
						</div>
					</div>
				</li>
				<li class="border-l border-gray-700 py-3 mx-2"></li>
				<li v-if="user" class="relative">
					<dropdown :right="true">
						<a href="#" class="relative px-4 flex items-center h-12 user hover:bg-gray-700">
                            {{user.name}} <i class="icon-arrow-down22 ml-3"></i>
						</a>
						<template #dropdown>
							<div class="w-40 text-gray-400">
								<router-link to="/account" class="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-600"><i class="icon-user mr-4"></i>Akun saya</router-link>
								<a @click.prevent="signOut" href="#" class="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-600"><i class="icon-switch2 mr-4"></i>Keluar</a>
							</div>
						</template>
					</dropdown>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import Dropdown from './Dropdown.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import {moment} from '../moment'
import {notification} from '../pusher'
export default {
    components: {
        Dropdown
    },
	data () {
		return {
			isLoading: false,
			show: false,
			tab: 'warning',
			notifications: [],
			warningCount: 0,
			activityCount: 0
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),
		totalNotification () {
			return this.warningCount + this.activityCount
		}
	},
	mounted () {
		let app = this
		document.addEventListener('click', this.closeTabMenu)
		notification.bind('warning', function () {
			//app.notifications.push(data.message)
			app.warningCount = app.warningCount + 1
		})
		notification.bind('activity', function () {
			app.activityCount = app.activityCount + 1
		})
	},
	methods: {
		...mapActions({
			logout: 'auth/logout'
		}),
		getData (e) {
			this.isLoading = true
			axios.get('/notification', {
				params: {
					type: e
				}
			})
			.then(res => {
				this.isLoading = false
				this.notifications = res.data.notifications
				this.warningCount = res.data.warning 
				this.activityCount = res.data.activity
				this.$refs.notif.scrollTop = 0
			})
		},
		onClickNotification () {
			this.show = !this.show
			this.tab = 'warning'
			if(this.show == true) {
				this.getData('warning')
			}
			if(this.warningCount > 0) {
				axios.put('/notification/isRead', {
					type: 'warning'
				})
				.then(() => {
					this.warningCount = 0
				})
			}
		},
		onDeleteNotification () {
			axios.post('/notification/delete')
			.then(() => {
				this.getData()
			})
		},
		getDate (d) {
			return moment (d)
		},
		tabMenu (e, m) {
			this.tab = m
			if(m == 'warning') {
				this.getData('warning')
			} else {
				this.getData('activity')
				if(this.activityCount > 0) {
					axios.put('/notification/isRead', {
						type: 'activity'
					})
					.then(() => {
						this.activityCount = 0
					})
				}
			}
		},
		async signOut () {
			this.isLoading = true
			const token = localStorage.getItem('refreshToken')
			await this.logout(token)
			.then(() => {
				this.$router.push('/login')
				this.isLoading = false
			})
		},
		closeTabMenu (e) {
			if(!e || !this.$refs.notification.contains(e.target)) {
				this.show = false
			}
		},
	},
	watch: {
		user (val) {
			if(val) {
				this.getData('warning')
			}
		}
	}
}
</script>