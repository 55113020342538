<template>
    <div class="m-5">
        <div class="grid grid-cols-12 gap-5 mb-5">    
            <div ref="top" class="rounded-sm overflow-hidden col-span-9 border ">
                <div id="map" class="w-full h-full" @mouseenter="sidebar = true" @mouseleave="sidebar = false">
                    <div v-show="!sidebar" class="absolute bg-blue-500 z-10 m-1 bg-opacity-70 text-blue-100 rounded-sm">
                        <div class="px-2 py-1 uppercase">{{activeSegment}}</div>
                    </div>
                    <div class="absolute text-gray-700 bg-cyan-500 z-10 h-full bg-opacity-50 transition ease-linear duration-300 transform -left-56" :class="{'translate-x-56': sidebar}">
                        <div class="p-2 border-b border-cyan-300"><i class="icon-air mr-2"></i>SEGMEN</div>
                        <ul class="h-full max-h-full overflow-scroll uppercase">
                            <li class="px-1 my-2">
                                <div class="relative flex items-center">
                                    <input v-model="search" type="text" class="h-6 border border-gray-400 focus:outline-none rounded-sm px-2" placeholder="cari segmen">
                                    <a v-show="search" @click.prevent="search = ''" href="#" class="absolute right-1 text-red-500"><i class="icon-cross3"></i></a>
                                </div>
                            </li>
                            <li>
                                <a @click="onClickSegment('', 'semua segmen')" href="#" :class="{'bg-cyan-600 bg-opacity-60 text-yellow-300': activeSegment=='semua segmen'}" class="p-2 hover:bg-cyan-600 hover:text-yellow-300 hover:bg-opacity-70 bg-opacity-40 block">SEMUA SEGMEN</a>
                            </li>
                            <li v-for="(segment, i) in segmentSearch" :key="i">
                                <a @click="onClickSegment(segment.station, segment.name)" href="#" :class="{'bg-cyan-600 bg-opacity-60 text-yellow-300': activeSegment== segment.name}" class="p-2 hover:bg-cyan-600 hover:text-yellow-300 hover:bg-opacity-70 bg-opacity-40 block">{{segment.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-span-3">
                <div class="bg-cyan-500 p-5 rounded-sm flex items-center justify-between mb-5 border border-transparent hover:border-cyan-300 cursor-pointer">
                    <div>
                        <i class="icon-station icon-3x text-cyan-300"></i>
                    </div>
                    <div class="text-right">
                        <div class="text-3xl text-cyan-100 font-medium">{{totalStations}}</div>
                        <div class="text-xs text-cyan-200">TOTAL STASIUN</div>
                    </div>
                </div>
                <div class="bg-green-500 p-5 rounded-sm flex items-center justify-between mb-5 border border-transparent hover:border-green-300 cursor-pointer">
                    <div>
                        <i class="icon-link2 icon-3x text-green-300"></i>
                    </div>
                    <div class="text-right">
                        <div class="text-3xl text-green-100 font-medium">{{connectedStations}}</div>
                        <div class="text-xs text-green-200">TERKONEKSI</div>
                    </div>
                </div>
                <div class="bg-red-500 p-5 rounded-sm flex items-center justify-between mb-5 border border-transparent hover:border-red-300 cursor-pointer">
                    <div>
                        <i class="icon-unlink2 icon-3x text-red-300"></i>
                    </div>
                    <div class="text-right">
                        <div class="text-3xl text-red-100 font-medium">{{notConnectedStations}}</div>
                        <div class="text-xs text-red-200">KONEKSI TERPUTUS</div>
                    </div>
                </div>
                <div class="bg-yellow-500 p-5 rounded-sm flex items-center justify-between border border-transparent hover:border-yellow-300 cursor-pointer">
                    <div>
                        <i class="icon-hammer-wrench icon-3x text-yellow-300"></i>
                    </div>
                    <div class="text-right">
                        <div class="text-3xl text-yellow-100 font-medium">{{mainteneceStations}}</div>
                        <div class="text-xs text-yellow-200">DALAM PERAWATAN</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- grafik pencemaran -->
        <div ref="cemar" class="rounded-sm overflow-hidden bg-gray-800">
            <div class="grid grid-cols-12 h-full">
                <div class="col-span-9">
                    <div class="grid grid-cols-7 h-full divide-x divide-gray-700 text-gray-400">
                        <!-- PH -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minPh}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxPh}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgPh}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('ph', sensor.minPh, sensor.maxPh, sensor.avgPh)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">BAKU MUTU ({{quality.minPh}}-{{quality.maxPh}})</div>
                            <div class="bg-cyan-600 text-center text-cyan-100">pH</div>
                        </div>
                        <!-- DO -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minDo}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxDo}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgDo}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('do', sensor.minDo, sensor.maxDo, sensor.avgDo)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.minDo}} mg/L)</div>
                            <div class="bg-purple-600 text-center text-purple-100">DO</div>
                        </div>
                        <!-- TURB -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minTurb}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxTurb}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgTurb}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('turb', sensor.minTurb, sensor.maxTurb, sensor.avgTurb)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.maxTurb}} mg/L)</div>
                            <div class="bg-orange-600 text-center text-orange-100">TURB</div>
                        </div>
                        <!-- SALT -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minSalt}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxSalt}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgSalt}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('salt', sensor.minSalt, sensor.maxSalt, sensor.avgSalt)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.maxSalt}} mg/L)</div>
                            <div class="bg-pink-600 text-center text-pink-100">SALT</div>
                        </div>
                        <!-- COND -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minCond}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxCond}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgCond}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('cond', sensor.minCond, sensor.maxCond, sensor.avgCond)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.maxCond}} mg/L)</div>
                            <div class="bg-gray-600 text-center text-gray-100">COND</div>
                        </div>
                        <!-- DEPT -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minDept}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxDept}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgDept}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('dept', sensor.minDept, sensor.maxDept, sensor.avgDept)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.maxDept}})</div>
                            <div class="bg-indigo-600 text-center text-indigo-100">DEPT</div>
                        </div>
                        <!-- TEMP -->
                        <div class="col-span-1 flex flex-col justify-between">
                            <div class="p-2">
                                <div class="flex items-center justify-between text-xs">
                                    <div class="text-center border-b border-yellow-500">
                                        <div>min</div>
                                        <div>{{sensor.minTemp}}</div>
                                    </div>
                                    <div class="text-center border-b border-red-500">
                                        <div>max</div>
                                        <div>{{sensor.maxTemp}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center text-xs border-b border-green-500 mx-2">
                                <div>avg</div>
                                <div>{{sensor.avgTemp}}</div>
                            </div>
                            <div class="grid grid-cols-2 h-full divide-x text-center items-center divide-gray-600">
                                <div>
                                    <div class="text-xs">STORET</div>
                                    <div class="text-lg">{{getScore('temp', sensor.minTemp, sensor.maxTemp, sensor.avgTemp)}}</div>
                                </div>
                                <div>
                                    <div class="text-xs">Ci/Li</div>
                                    <div class="text-lg">--</div>
                                </div>
                            </div>
                            <div class="text-xs px-2 text-center">({{quality.minTemp}} - {{quality.maxTemp}}) ℃</div>
                            <div class="bg-red-600 text-center text-red-100">TEMP</div>
                        </div>
                        
                    </div>
                </div>
                <div :class="storetColor.color" class="col-span-3 flex flex-col justify-between items-center text-gray-900">
                    <div class="font-semibold uppercase pt-3">{{activeSegment}}</div>
                    <div class="grid grid-cols-2 gap-2 text-center">
                        <div class="col-span-1 border border-gray-900 rounded-sm p-2">
                            <div>STORET</div>
                            <div class="text-3xl">{{storetScore}}</div>
                             <div>{{storetColor.text}}</div>
                        </div>
                        <div class="col-span-1 border border-gray-900 rounded-sm p-2">
                            <div>INDEX</div>
                            <div class="text-3xl">--</div>
                             <div>{{storetColor.text}}</div>
                        </div>
                    </div>
                   
                    <div class="px-5 text-center">DATA 7 HARI TERAKHIR</div>
                </div>
            </div>
        </div>

        <!-- bottom -->
        <div ref="bottom" class="h-12 fixed bottom-0 w-9"></div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import axios from 'axios'
import {notification} from '../pusher'
export default {
    data () {
        return {
            search: '',
            sidebar: false,
            map: '',
            canvas: '',
            segment: '',
            quality: '',
            segments: [],
            stations: [],
            stationList: [],
            activeSegment: 'semua segmen',
            storet: {
                ph: 0,
                do: 0,
                cond: 0,
                turb: 0,
                temp: 0,
                salt: 0,
                dept: 0
            },
            storetStation: {
                ph: 0,
                do: 0,
                cond: 0,
                turb: 0,
                temp: 0,
                salt: 0,
                dept: 0
            }
        }
    },
    
    mounted () {
        let app = this
        notification.bind('activity', function() {
            app.getData()
        })
        // function cemar height
        const height = this.$refs.bottom.offsetTop - this.$refs.top.clientHeight - 119
        if(height > 180) {
            this.$refs.cemar.style.height = height+'px'
        } else {
            this.$refs.cemar.style.height = '200px'
        }

        // map function
        const bound = [
            [105.20998957450172, -7.810274173546404],
            [108.99362622549745, -5.887748973008655]
        ]
        mapboxgl.accessToken= "pk.eyJ1IjoibmVuZGkiLCJhIjoiY2t4cTQweHg3NzEwaDJvbXVud2RsbWxwNiJ9.C080KdMXt4y_4oSjHaMnmw"
		this.map = new mapboxgl.Map({
			container: 'map', // container ID
			style: 'mapbox://styles/mapbox/streets-v11', // style URL
			center: [107.1018079, -6.849980216], // starting position [lng, lat]
			zoom: 7.3, // starting zoom
            minZoom: 7.3,
            maxBounds: bound,
			attributionControl: false
		});
        
        this.canvas = this.map.getCanvasContainer()

        const size = 100
		const pulsingDotActive = {
			width: size,
			height: size,

			data: new Uint8Array(size * size * 4),

			onAdd: function () {
				const canvas = document.createElement('canvas');
				canvas.width = this.width;
				canvas.height = this.height;
				this.context = canvas.getContext('2d');
			},

			render: function () {
				const duration = 1000;
				const t = (performance.now() % duration) / duration;

				const radius = (size / 2) * 0.3;
				const outerRadius = (size / 2) * 0.4 * t + radius;
				const context = this.context;

				context.clearRect(0, 0, this.width, this.height);
				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					outerRadius,
					0,
					Math.PI * 2
				);

				context.fillStyle = `rgba(9,157,0, ${1 - t})`;
				context.fill();

				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					radius,
					0,
					Math.PI * 2
				);

				context.fillStyle = 'rgba(9,157,0, 1)';
				context.strokeStyle = 'white';
				context.lineWidth = 2 + 4 * (1 - t);
				context.fill();
				context.stroke();

				this.data = context.getImageData(
					0,
					0,
					this.width,
					this.height
				).data;

				app.map.triggerRepaint();
				return true
			}
		} 
        const pulsingDotDeactive = {
			width: size,
			height: size,

			data: new Uint8Array(size * size * 4),

			onAdd: function () {
				const canvas = document.createElement('canvas');
				canvas.width = this.width;
				canvas.height = this.height;
				this.context = canvas.getContext('2d');
			},

			render: function () {
				const duration = 1000;
				const t = (performance.now() % duration) / duration;

				const radius = (size / 2) * 0.3;
				const outerRadius = (size / 2) * 0.4 * t + radius;
				const context = this.context;

				context.clearRect(0, 0, this.width, this.height);
				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					outerRadius,
					0,
					Math.PI * 2
				);

				context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
				context.fill();

				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					radius,
					0,
					Math.PI * 2
				);

				context.fillStyle = 'rgba(255, 100, 100, 1)';
				context.strokeStyle = 'white';
				context.lineWidth = 2 + 4 * (1 - t);
				context.fill();
				context.stroke();

				this.data = context.getImageData(
					0,
					0,
					this.width,
					this.height
				).data;

				app.map.triggerRepaint();
				return true
			}
		} 

        const pulsingDotMaintenence = {
			width: size,
			height: size,

			data: new Uint8Array(size * size * 4),

			onAdd: function () {
				const canvas = document.createElement('canvas');
				canvas.width = this.width;
				canvas.height = this.height;
				this.context = canvas.getContext('2d');
			},

			render: function () {
				const duration = 1000;
				const t = (performance.now() % duration) / duration;

				const radius = (size / 2) * 0.3;
				const outerRadius = (size / 2) * 0.4 * t + radius;
				const context = this.context;

				context.clearRect(0, 0, this.width, this.height);
				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					outerRadius,
					0,
					Math.PI * 2
				);

				context.fillStyle = `rgba(245,158,11, ${1 - t})`;
				context.fill();

				context.beginPath();
				context.arc(
					this.width / 2,
					this.height / 2,
					radius,
					0,
					Math.PI * 2
				);

				context.fillStyle = 'rgba(245,158,11, 1)';
				context.strokeStyle = 'white';
				context.lineWidth = 2 + 4 * (1 - t);
				context.fill();
				context.stroke();

				this.data = context.getImageData(
					0,
					0,
					this.width,
					this.height
				).data;

				app.map.triggerRepaint();
				return true
			}
		} 
        this.map.on('load', () => {
            this.map.addImage('marker-active', pulsingDotActive, {pixelRatio: 2})
			this.map.addImage('marker-deactive', pulsingDotDeactive, {pixelRatio: 2})
			this.map.addImage('marker-maintenence', pulsingDotMaintenence, {pixelRatio: 2})
            this.map.addSource('segment', {
				type: 'geojson',
				data: this.geojson
			})
            this.map.addLayer({
                'id': 'segment',
                'type': 'symbol',
                'source': 'segment',
                'layout': {
                    'visibility': 'visible',
                    'icon-image': ['match', ['get', 'status'], 'connected', 'marker-active', 'disconnected', 'marker-deactive', 'marker-maintenence'],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    // get the title name from the source's "title" property
                    'text-field': ['get', 'title'],
                    'text-size': 12,
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-offset': [0, 1.40],
                    'text-anchor': 'top'
                },
            });
           
        })
        this.map.on('mouseenter', 'segment', () => {
            this.canvas.style.cursor = 'pointer'
        })
        this.map.on('mouseleave', 'segment', () => {
            this.canvas.style.cursor = ''
        })

        this.map.on('click', 'segment', (e) => {
			const coordinates = e.features[0].geometry.coordinates
			new mapboxgl.Popup()
			.setLngLat(coordinates)
            .setMaxWidth('600px')
			.setHTML(`<div class="p-2" style="width: 550px">
                        <div class="flex justify-between mb-3">
						    <div>
                                <div class="flex">
                                    <div class="font-mendium">${e.features[0].properties.title}</div>
                                    <div class="border rounded-sm px-3 ml-3">${e.features[0].properties.status}</div>
                                    <button class="bg-green-500 text-green-50 px-3 rounded-sm ml-3">RealTime</button>
                                </div>
                                <div>${e.features[0].properties.address}</div>
                            </div>
                            <div class="flex">
                                <div class="border rounded-sm p-2 text-center mr-2">
                                    <div>STORET</div>
                                    <div>${e.features[0].properties.score}</div>
                                </div>
                                <div class="border rounded-sm p-2 text-center">
                                    <div>INDEX</div>
                                    <div>--</div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-7 divide-x">

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minPh}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgPh.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxPh}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetPh}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-cyan-500 text-cyan-50">
                                    <div>pH</div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minDo}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgDo.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxDo}</td>
                                    </tr>
                                </table>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetDo}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-purple-500 text-purple-50">
                                    <div>DO</div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minTurb}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgTurb.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxTurb}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetTurb}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-orange-500 text-orange-50">
                                    <div>TURB</div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minSalt}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgSalt.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxSalt}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetSalt}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-pink-500 text-pink-50">
                                    <div>SALT</div>
                                </div>
                            </div>
                            
                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minCond}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgCond.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxCond}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetCond}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-gray-500 text-gray-50">
                                    <div>COND</div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minDept}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgDept.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxDept}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetDept}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-indigo-500 text-indigo-50">
                                    <div>DEPT</div>
                                </div>
                            </div>

                            <div class="flex flex-col justify-between text-center text-xs">
                                <table class="w-full">
                                    <tr class="text-yellow-500">
                                        <td class="text-left pl-1">min</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.minTemp}</td>
                                    </tr>
                                    <tr class="text-green-500">
                                        <td class="text-left pl-1">avg</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.avgTemp.toFixed(2)}</td>
                                    </tr>
                                    <tr class="text-red-500">
                                        <td class="text-left pl-1">max</td>
                                        <td class="text-left">:</td>
                                        <td class="text-right pr-1">${e.features[0].properties.maxTemp}</td>
                                    </tr>
                                </table>
                                <div class="grid grid-cols-2 text-center border-t mt-1 pt-1">
                                    <div>
                                        <div>STRT</div>
                                        <div>${e.features[0].properties.storetTemp}</div>
                                    </div>
                                    <div>
                                        <div>Ci/Li</div>
                                        <div>--</div>
                                    </div>
                                </div>
                                <div class="bg-red-500 text-red-50">
                                    <div>TEMP</div>
                                </div>
                            </div>
                            
                        </div>
					</div>`)
			.addTo(this.map)
		})

        this.getData()
    },
    computed: {
        segmentSearch () {
            return this.segments.filter(obj => {
                return obj.name.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        getScoreStation () {
            let sum = 0
            for(const i in this.storetStation) {
                 if (Object.hasOwnProperty.call(this.storet, i)) {
                    sum += this.storetStation[i]
                    console.log(this.storetStation[i]);
                }
            }
            return sum
        },
        storetScore () {
            let sum = 0
            for (const i in this.storet) {
                if (Object.hasOwnProperty.call(this.storet, i)) {
                    sum += this.storet[i];
                }
            }
            return sum
        },
        storetColor () {
            let res = {
                color: '',
                text: ''
            };
            if(this.storetScore == 0) {
                res.color = 'bg-green-500'
                res.text = 'BAIK'
            }
            if(this.storetScore <= -1) {
                res.color = 'bg-blue-500'
                res.text = 'CEMAR RINGAN'
            }
            if(this.storetScore <= -11) {
                res.color = 'bg-yellow-500'
                res.text = 'CEMAR SEDANG'
            }
            if(this.storetScore < -30) {
                res.color = 'bg-red-500'
                res.text = 'CEMAR BERAT'
            }
            return res
        },
        sensor () {
            const logger = []
                for (const i in this.stations) {
                    const element = this.stations[i];
                    logger.push(...element.logger)
                }
                const maxPh = Math.max(...logger.map(obj => obj.maxPh))
                const minPh = Math.min(...logger.map(obj => obj.minPh))
                const avgPh = getAvg(logger, 'avgPh')
                const maxDo = Math.max(...logger.map(obj => obj.maxDo))
                const minDo = Math.min(...logger.map(obj => obj.minDo))
                const avgDo = getAvg(logger, 'avgDo')
                const maxCond = Math.max(...logger.map(obj => obj.maxCond))
                const minCond = Math.min(...logger.map(obj => obj.minCond))
                const avgCond = getAvg(logger, 'avgCond')
                const maxTurb = Math.max(...logger.map(obj => obj.maxTurb))
                const minTurb = Math.min(...logger.map(obj => obj.minTurb))
                const avgTurb = getAvg(logger, 'avgTurb')
                const maxTemp = Math.max(...logger.map(obj => obj.maxTemp))
                const minTemp = Math.min(...logger.map(obj => obj.minTemp))
                const avgTemp = getAvg(logger, 'avgTemp')
                const maxSalt = Math.max(...logger.map(obj => obj.maxSalt))
                const minSalt = Math.min(...logger.map(obj => obj.minSalt))
                const avgSalt = getAvg(logger, 'avgSalt')
                const maxDept = Math.max(...logger.map(obj => obj.maxDept))
                const minDept = Math.min(...logger.map(obj => obj.minDept))
                const avgDept = getAvg(logger, 'avgDept')

                function getAvg (arr, key) {
                    let sum = 0;
                    for (const i in arr) {
                        const el = arr[i][key]
                        sum += el
                    }
                    return (sum / arr.length).toFixed(2)
                }

            return {
                maxPh: maxPh,
                minPh: minPh, 
                avgPh: avgPh,
                maxDo: maxDo,
                minDo: minDo,
                avgDo: avgDo,
                maxCond: maxCond,
                minCond: minCond,
                avgCond: avgCond,
                maxTurb: maxTurb,
                minTurb: minTurb,
                avgTurb: avgTurb,
                maxTemp: maxTemp,
                minTemp: minTemp,
                avgTemp: avgTemp,
                maxSalt: maxSalt,
                minSalt: minSalt,
                avgSalt: avgSalt,
                maxDept: maxDept,
                minDept: minDept,
                avgDept: avgDept,
            }

        },
        totalStations () {
            return this.stations.length
        },
        connectedStations () {
            return this.stations.filter(obj => obj.status == 'connected').length
        },
        notConnectedStations () {
            return this.stations.filter(obj => obj.status == 'disconnected').length
        },
        mainteneceStations  () {
            return this.stations.filter(obj => obj.status == 'maintenence').length
        },
        geojson () {
            let app = this
            const data = {type: 'FeatureCollection', features: []}
            const stations = this.stations
            for (let i = 0; i < stations.length; i++) {
                const el = stations[i];
                if(el.logger[0]) {
                    let storetPh = app.getScore('ph', el.logger[0].minPh, el.logger[0].maxPh, el.logger[0].avgPh, 'station')
                    let storetDo = app.getScore('do', el.logger[0].minDo, el.logger[0].maxDo, el.logger[0].avgDo, 'station')
                    let storetCond = app.getScore('cond', el.logger[0].minCond, el.logger[0].maxCond, el.logger[0].avgCond, 'station')
                    let storetTurb = app.getScore('turb', el.logger[0].minTurb, el.logger[0].maxTurb, el.logger[0].avgTurb, 'station')
                    let storetTemp = app.getScore('temp', el.logger[0].minTemp, el.logger[0].maxTemp, el.logger[0].avgTemp, 'station')
                    let storetSalt = app.getScore('salt', el.logger[0].minSalt, el.logger[0].maxSalt, el.logger[0].avgSalt, 'station')
                    let storetDept = app.getScore('dept', el.logger[0].minDept, el.logger[0].maxDept, el.logger[0].avgDept, 'station')
                    let score = storetPh + storetDo + storetCond + storetTurb + storetTemp + storetSalt + storetDept
                    data.features.push({
                        type: 'Feature',
                        properties: {
                            status: el.status,
                            title: el.name,
                            address: el.address,
                            maxPh: el.logger[0].maxPh,
                            minPh: el.logger[0].minPh, 
                            avgPh: el.logger[0].avgPh,
                            storetPh: storetPh,
                            maxDo: el.logger[0].maxDo,
                            minDo: el.logger[0].minDo,
                            avgDo: el.logger[0].avgDo,
                            storetDo: storetDo,
                            maxCond: el.logger[0].maxCond,
                            minCond: el.logger[0].minCond,
                            avgCond: el.logger[0].avgCond,
                            storetCond: storetCond,
                            maxTurb: el.logger[0].maxTurb,
                            minTurb: el.logger[0].minTurb,
                            avgTurb: el.logger[0].avgTurb,
                            storetTurb: storetTurb,
                            maxTemp: el.logger[0].maxTemp,
                            minTemp: el.logger[0].minTemp,
                            avgTemp: el.logger[0].avgTemp,
                            storetTemp: storetTemp,
                            maxSalt: el.logger[0].maxSalt,
                            minSalt: el.logger[0].minSalt,
                            avgSalt: el.logger[0].avgSalt,
                            storetSalt: storetSalt,
                            maxDept: el.logger[0].maxDept,
                            minDept: el.logger[0].minDept,
                            avgDept: el.logger[0].avgDept,
                            storetDept: storetDept,
                            score: score
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: el.coordinates
                        }
                    })
                } else {
                    data.features.push({
                        type: 'Feature',
                        properties: {
                            status: el.status,
                            title: el.name,
                            maxPh: 0,
                            minPh: 0,
                            avgPh: 0,
                            storetPh: 0,
                            maxDo: 0,
                            minDo: 0,
                            avgDo: 0,
                            storetDo: 0,
                            maxCond: 0,
                            minCond: 0,
                            avgCond: 0,
                            storetCond: 0,
                            maxTurb: 0,
                            minTurb: 0,
                            avgTurb: 0,
                            storetTurb: 0,
                            maxTemp: 0,
                            minTemp: 0,
                            avgTemp: 0,
                            storetTemp: 0,
                            maxSalt: 0,
                            minSalt: 0,
                            avgSalt: 0,
                            storetSalt: 0,
                            maxDept: 0,
                            minDept: 0,
                            avgDept: 0,
                            storetDept: 0,
                            score: 0
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: el.coordinates
                        }
                    })
                }
            }
            return data
        }
    },
    methods: {
        onClickSegment (station, active) {
            if(station) {
                this.activeSegment = active 
                this.stations = station
            } else {
                this.activeSegment = active 
                this.stations = this.stationList
            }
            this.$nextTick(() => {
                this.map.getSource('segment').setData(this.geojson)
            })
            const coordinates = []
            const geojson = this.geojson.features
            for(let geo of geojson) {
                coordinates.push(geo.geometry.coordinates) 
            }
            const bounds = new mapboxgl.LngLatBounds(
                coordinates[0],
                coordinates[0]
            )
            for (const coord of coordinates) {
                bounds.extend(coord);
            }
            this.map.fitBounds(bounds, {
                padding: {top: 30, bottom: 30, left: 140, right: 30}
            });
        },

        getData () {
            this.segments = []
            this.stationList = []
            this.stations = []
            axios.get('/dashboard')
            .then(res => {
                const segments = res.data.segments
                this.segments = segments
                this.quality = res.data.early

                for (let i in segments) {
                    this.stations.push(...segments[i].station)
                    this.stationList.push(...segments[i].station)
                }

            })
        },
        getScore (key, min, max , avg, mode) {
            let smin, smax, savg, score
            if(key == 'ph') {

                smin =  (min > this.quality.maxPh) || (min < this.quality.minPh) ? -2 : 0
                smax =  (max > this.quality.maxPh) || (max < this.quality.minPh) ? -2 : 0
                savg =  (avg > this.quality.maxPh) || (avg < this.quality.minPh) ? -6 : 0
                if(this.quality.maxPh > 0 && this.quality.minPh > 0) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.ph = score
                } else {
                    this.storet.ph = score
                }
            }
            if(key == 'do') {
                smin = (min < this.quality.minDo) ? -2 : 0
                smax = (max < this.quality.minDo) ? -2 : 0
                savg = (avg < this.quality.minDo) ? -6 : 0
                if(this.quality.minDo > 0) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.do = score
                } else {
                    this.storet.do = score
                }
            }
            if(key == 'cond') {
                smin = (min > this.quality.maxCond) ? -2 : 0
                smax = (max > this.quality.maxCond) ? -2 : 0
                savg = (avg > this.quality.maxCond) ? -6 : 0
                if(this.quality.maxCond > 0 && this.quality.minCond > 0) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.cond = score
                } else {
                    this.storet.cond = score
                }
            }
            if(key == 'turb') {
                smin = (min > this.quality.maxTurb) ? -2 : 0
                smax = (max > this.quality.maxTurb) ? -2 : 0
                savg = (avg > this.quality.maxTurb) ? -6 : 0
                if(this.quality.maxTurb > 0) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.turb = score
                } else {
                    this.storet.turb = score
                }
            }
            if(key == 'temp') {
                smin =  (min > this.quality.maxTemp) || (min < this.quality.minTemp) ? -1 : 0
                smax =  (max > this.quality.maxTemp) || (max < this.quality.minTemp) ? -1 : 0
                savg =  (avg > this.quality.maxTemp) || (avg < this.quality.minTemp) ? -3 : 0
                score = smin + smax + savg
                if(mode == 'station') {
                    this.storetStation.temp = score
                } else {
                    this.storet.temp = score
                }
            }
            if(key == 'salt') {
                smin = (min > this.quality.maxSalt) ? -2 : 0
                smax = (max > this.quality.maxSalt) ? -2 : 0
                savg = (avg > this.quality.maxSalt) ? -6 : 0
                if(this.quality.maxSalt > 0 ) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.salt = score
                } else {
                    this.storet.salt = score
                }
            }
            if(key == 'dept') {
                smin = (min > this.quality.maxDept) ? -2 : 0
                smax = (max > this.quality.maxDept) ? -2 : 0
                savg = (avg > this.quality.maxDept) ? -6 : 0
                if(this.quality.maxDept > 0 ) {
                    score = smin + smax + savg
                } else {
                    score = 0
                }
                if(mode == 'station') {
                    this.storetStation.dept = score
                } else {
                    this.storet.dept = score
                }
            }
            return score
        }
    },
    watch: {
        geojson (val) {
            if(this.map.getSource('segment')) {
                this.map.getSource('segment').setData(val)
            }
        }
    },
    destroyed () {
        notification.unbind('activity')
        this.map.remove()
    }
}
</script>
<style>
    .mapboxgl-popup-close-button {
        padding-right: 5px;
        padding-left: 5px;
    }
</style>

