import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { rtdbPlugin } from 'vuefire'

import './store/subscribe'

axios.defaults.baseURL = process.env.VUE_APP_API
window.$ = window.jQuery =  require('jquery')
require('./datepicker.js')
import './datepicker.css'
import './index.css'
import './assets/icons/icomoon/styles.min.css'
Vue.config.productionTip = false
Vue.use(rtdbPlugin)
axios.interceptors.response.use(
  response => {
    return response
  }, 
  async err => {
    const originalRequiest = err.config
    const {response: {status, data}} = err
    if(status == 403 && data.message == 'jwt expired') {
      await store.dispatch('auth/refresh_token', localStorage.getItem('refreshToken'))
      originalRequiest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
      return axios(originalRequiest)
    }
    if(status == 403 && data.message == 'invalid signature') {
      store.dispatch('auth/logout')
    }
    return Promise.reject(err)
})

store.dispatch('auth/attempt', localStorage.getItem('token'))
store.dispatch('auth/me')
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
