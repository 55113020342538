<template>
	<div class="m-5">
		<div class="bg-gray-800 rounded-sm">
			<div class="flex items-center justify-between p-5">
				<div class="flex">
					<calendar @date="onSelectDate"></calendar>
					<dropdown-segment @blur="onSelectSegment" class="ml-3"></dropdown-segment>
					<div v-show="segmentId">
						<dropdown-station  :stations="stations" @blur="onSelectStation" class="ml-3"></dropdown-station>
					</div>
				</div>
				<div class="flex">
					<div class="mr-3">
						<button @click="onFilter('1 Jam')" class="h-9 bg-gray-700 border border-r-0 border-gray-600 rounded-l-sm focus:outline-none px-5 text-gray-200" :class="[filter=='1 Jam' ? 'bg-green-500 text-white border-green-500 hover:bg-green-500': 'hover:bg-green-600 hover:border-green-500']">Per 1 Jam</button>
						<button @click="onFilter('1 Hari')" class="h-9 bg-gray-700 border border-gray-600 focus:outline-none px-5 text-gray-200 hover:bg-green-500 hover:border-green-500" :class="[filter=='1 Hari' ? 'bg-green-500 text-white border-green-500 hover:bg-green-500': 'hover:bg-green-600 hover:border-green-500']">Per 1 Hari</button>
					</div>
					<button @click="download" class="h-9 px-5 bg-green-600 rounded-sm text-green-50 hover:bg-green-700">DOWNLOAD</button>
				</div>
			</div>

			<table class="w-full text-gray-400">
				<thead>
					<tr>
						<th class="py-3 pl-5 pr-3 bg-gray-700">Tanggal</th>
						<th class="p-3 bg-gray-700" style="width: 10%">Stasiun</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">pH</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">DO</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">Turb</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">Salt</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">Cond</th>
						<th class="p-3 pr-8 bg-gray-700 text-right">Dept</th>
						<th class="py-3 pl-3 pr-8 bg-gray-700 text-right">Temp</th>
					</tr>
				</thead>
				<tbody v-if="isLoading" class="divide-y divide-gray-600">
					<tr v-for="i in 20" :key="i">
						<td class="py-3 pl-5 pr-3">
							<div class="ph-item h-5 w-full bg-gray-700 mb-1"></div>
						</td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="p-3"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
						<td class="py-3 pl-3 pr-5"><div class="ph-item h-5 w-full bg-gray-700"></div></td>
					</tr>
					</tbody>
				<tbody v-if="!isLoading" class="divide-y divide-gray-700">
					<tr v-for="(logger, i) in loggers" :key="i">
						<td class="py-3 pl-5 pr-3">
							<div>{{getDate(logger.addedAt).substring(10,0)}} <span v-show="filter == '1 Jam'" class="ml-1 text-orange-600">{{getDate(logger.addedAt).substring(10,19)}}</span></div>
						</td>
						<td class="p-3 truncate">
							<div>{{logger.station}}</div>
							<div class="text-xs text-yellow-300 text-opacity-80">{{logger.segment}}</div>
						</td>
						<td class="p-3 text-right">{{(logger.ph).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].ph < loggers[i+1].ph "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].ph > loggers[i+1].ph "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].ph == loggers[i+1].ph "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.do).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].do < loggers[i+1].do "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].do > loggers[i+1].do "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].do == loggers[i+1].do "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.turb).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].turb < loggers[i+1].turb "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].turb > loggers[i+1].turb "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].turb == loggers[i+1].turb "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.salt).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].salt < loggers[i+1].salt "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].salt > loggers[i+1].salt "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].salt == loggers[i+1].salt "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.cond).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].cond < loggers[i+1].cond "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].cond > loggers[i+1].cond "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].cond == loggers[i+1].cond "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.dept).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].dept < loggers[i+1].dept "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].dept > loggers[i+1].dept "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].dept == loggers[i+1].dept "><i class="icon-menu-open"></i></span>
						</td>
						<td class="p-3 text-right">{{(logger.temp).toFixed(2)}} 
							<span v-if="i < loggers.length -1 && loggers[i].temp < loggers[i+1].temp "><i class="icon-arrow-down5 text-green-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].temp > loggers[i+1].temp "><i class="icon-arrow-up5 text-red-500"></i></span>
                            <span v-if="i < loggers.length -1 && loggers[i].temp == loggers[i+1].temp "><i class="icon-menu-open"></i></span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="text-right mt-5">
			<paginate :pages="pages" @get-page="getData($event)"></paginate>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import Calendar from '../components/calendar.vue'
import DropdownSegment from '../components/DropdownSegment.vue'
import DropdownStation from '../components/DropdownStation.vue'
import {moment} from '../moment'
import paginate from '../components/paginate.vue'
export default {
	components: {Calendar,DropdownSegment, DropdownStation, paginate},
	data () {
		return {
			isLoading: false,
			date: new Date(),
			label: 'Hari ini',
			segmentId: '',
			stations: '',
			segmentName: 'Semua segment',
			stationId: '',
			station: 'Semua stasiun',
			filter: '1 Jam',
			loggers: [],
			pages: ''
		}
	},
	mounted () {
		this.getData()
	},
	methods: {
		getData (e) {
			window.scrollTo(0,0)
			this.isLoading = true
			axios.get('/logger', {
				params: {
					page: e,
					date: this.date,
					label: this.label,
					segmentId: this.segmentId,
					stationId: this.stationId,
					filter: this.filter
				}
			})
			.then(res => {
				this.isLoading = false
				this.loggers = res.data.data
				this.pages = res.data.pages
			})
			.catch(() => {
				this.isLoading = false
			})
		},
		download () {
			axios.get('/logger/download', {
				params: {
					date: this.date,
					label: this.label,
					segmentId: this.segmentId,
					segmentName: this.segmentName,
					stationId: this.stationId,
					station: this.station,
					filter: this.filter
				},
				responseType: 'blob'
			})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'laporan.xlsx'); //or any other extension
				document.body.appendChild(link);
				link.click();
			})
		},
		onSelectDate (d, l) {
			this.date = d
			this.label = l
			this.$nextTick(() => {
				this.getData()
			})

		},
		onSelectSegment (id, stations, name) {
			this.segmentId = id
			this.stations = stations
			this.segmentName = name || 'Semu segment'
		},
		onSelectStation (id , name) {
			this.stationId = id
			this.station = name || 'Semua stasiun'
			this.$nextTick(() => {
				this.getData()
			})
		},
		onFilter (v) {
			this.filter = v
			this.filter = v
			this.$nextTick(() => {
				this.getData()
			})
		},
		getDate (d) {
			return moment(d)
		}
	}
}
</script>