<template>
    <div class="m-5">
        <div class="bg-gray-800 rounded-sm">
            <div class="flex items-center justify-between p-5">
                <div class="flex">
                    <calendar @date="onSelectDate"></calendar>
                    <dropdown-segment @blur="onSelectSegment" class="ml-3"></dropdown-segment>
                    <div v-show="segmentId">
                        <dropdow-station :stations="stations" class="ml-3" @blur="onSelectStation"></dropdow-station>
                    </div>
                </div>
                <div class="flex">
                    <div class="ml-3">
                        <button @click="onFilter('semua')" class="h-9 bg-gray-700 border border-r-0 border-gray-600 rounded-l-sm focus:outline-none px-3 text-gray-200" :class="[filter=='semua' ? 'bg-green-500 text-white border-green-500 hover:bg-green-500': 'hover:bg-green-600 hover:border-green-500']">Semua</button>
                        <button @click="onFilter('min')" class="h-9 bg-gray-700 border border-gray-600 focus:outline-none px-3" :class="[filter=='min' ? 'bg-yellow-600 text-yellow-50 border-yellow-600  hover:bg-yellow-600': 'hover:bg-yellow-700 hover:border-yellow-600 text-yellow-500']">min</button>
                        <button @click="onFilter('max')" class="h-9 bg-gray-700 border border-gray-600 border-l-0 rounded-r-sm focus:outline-none px-3" :class="[filter=='max' ? 'bg-red-500 text-white border-red-500 hover:bg-red-500': 'hover:bg-red-700 hover:border-red-500 text-red-500']">Max</button>
                    </div>
                    <div class="ml-3 mr-3">
                        <dropdown>
                            <button type="button" class="bg-gray-700 border border-gray-600 relative rounded-sm h-9 focus:outline-none">
                                <div class="flex items-center h-full relative">
                                    <div class="bg-gray-800 bg-opacity-50 text-gray-300 h-full w-9 rounded-l-sm flex justify-center items-center">
                                        <i class="icon-filter3"></i>
                                    </div>
                                    <div class="px-3 text-gray-300 text-left">
                                        Filter parameter
                                    </div>
                                </div>
                                <div class="absolute w-full h-full top-0 rounded-sm hover:bg-opacity-5 hover:bg-gray-400"></div>
                            </button>
                            <template #dropdown>
                                <div class="w-40 text-gray-300">
                                    <ul>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('pH')" href="#" :class="{'bg-green-500 text-white':parameters.ph}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>pH</div>
                                                    <div v-show="parameters.ph"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('DO')" href="#" :class="{'bg-green-500 text-white':parameters.do}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>DO</div>
                                                    <div v-show="parameters.do"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('COND')" href="#" :class="{'bg-green-500 text-white':parameters.cond}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Cond</div>
                                                    <div v-show="parameters.cond"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('TURB')" href="#" :class="{'bg-green-500 text-white':parameters.turb}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Turb</div>
                                                    <div v-show="parameters.turb"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('TEMP')"  href="#" :class="{'bg-green-500 text-white':parameters.temp}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Temp</div>
                                                    <div v-show="parameters.temp"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('SALT')" href="#" :class="{'bg-green-500 text-white':parameters.salt}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Salt</div>
                                                    <div v-show="parameters.salt"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                        <li class="hover:bg-gray-600">
                                            <a @click.prevent="onParameter('DEPT')" href="#" :class="{'bg-green-500 text-white':parameters.dept}" class="inline-block px-5 py-3 w-full">
                                                <div class="flex items-center justify-between">
                                                    <div>Dept</div>
                                                    <div v-show="parameters.dept"><i class="icon-check2"></i></div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </dropdown>
                    </div>
                    <div>
                        <tooltip :text="'Pengaturan batas ambang'" :place="'right'">
                            <button @click="modal=true" class="h-9 w-9 bg-blue-500 text-blue-100 rounded-sm"><i class="icon-cog3"></i></button>
                        </tooltip>
                    </div>
                </div>
            </div>
            <div class="px-5 flex items-center space-x-3">
                <div v-show="parameters.ph" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-cyan-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>pH</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('pH')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.do" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-purple-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>DO</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('DO')"  href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.cond" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-gray-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>Cond</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('COND')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.turb" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-orange-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>Turb</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('TURB')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.temp" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-red-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>Temp</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('TEMP')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.salt" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-pink-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>Salt</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('SALT')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-show="parameters.dept" class="px-3 py-2 bg-gray-700 text-gray-300 shadow-md rounded-r-sm mb-5 border-l-4 border-indigo-500">
                    <div class="flex items-center">
                        <div></div>
                        <div>Dept</div>
                        <div class="ml-5">
                            <a @click.prevent="onDeletParameter('DEPT')" href="#" class="">
                                <i class="icon-cross3 hover:text-red-500"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
            <table class="w-full">
                 <thead>
                    <tr>
                        <th @click="sortBy('addedAt')" :class="{'text-green-500': sortKey == 'addedAt'}" class="px-5 py-3 bg-gray-700 text-gray-400 hover:text-green-500  cursor-pointer">
                            WAKTU
                            <i v-if="sortKey == 'addedAt'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('station.name')" :class="{'text-green-500': sortKey == 'station.name'}" class="px-5 py-3 bg-gray-700 text-gray-400 hover:text-green-500  cursor-pointer">
                            STASIUN
                            <i v-if="sortKey == 'station.name'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('description')" :class="{'text-green-500': sortKey == 'description'}" class="px-5 py-3 bg-gray-700 text-gray-400 hover:text-green-500  cursor-pointer">
                            KETERANGAN
                            <i v-if="sortKey == 'description'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('sensor')" :class="{'text-green-500': sortKey == 'sensor'}" class="px-5 py-3 bg-gray-700 text-gray-400 hover:text-green-500  cursor-pointer">
                            PARAMETER
                            <i v-if="sortKey == 'sensor'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                        <th @click="sortBy('value')" :class="{'text-green-500': sortKey == 'value'}"  class="px-5 py-3 bg-gray-700 text-gray-400 hover:text-green-500  cursor-pointer text-right">
                            NILAI
                            <i v-if="sortKey == 'value'" :class="[sortOrder === 'asc' ? 'icon-arrow-up12':'icon-arrow-down12']"></i>
                            <i v-else class="icon-menu-open"></i>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="isLoading" class="divide-y divide-gray-700">
                    <tr v-for="i in 20" :key="i">
                        <td class="py-3.5 px-5">
                            <div class="h-5 bg-gray-700 ph-item"></div>
                        </td>
                        <td class="py-3.5 px-5">
                            <div class="h-5 bg-gray-700 ph-item"></div>
                        </td>
                        <td class="py-3.5 px-5">
                            <div class="h-5 bg-gray-700 ph-item"></div>
                        </td>
                        <td class="py-3.5 px-5">
                            <div class="h-5 bg-gray-700 ph-item"></div>
                        </td>
                        <td class="py-3.5 px-5">
                            <div class="h-5 bg-gray-700 ph-item"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!isLoading" class="divide-y divide-gray-700">
                    <tr v-for="(warning, i) in warnings" :key="i">
                        <td class="py-3 px-5" :class="[warning.threshold == 'max'? 'text-red-600':'text-yellow-600']">{{getDate(warning.addedAt)}}</td>
                        <td class="py-3 px-5" :class="[warning.threshold == 'max'? 'text-red-600':'text-yellow-600']">
                            <div>{{warning.station}}</div>
                            <div class="text-xs text-gray-400">{{warning.segment}}</div>
                        </td>
                        <td class="py-3 px-5" :class="[warning.threshold == 'max'? 'text-red-600':'text-yellow-600']">{{warning.description}}</td>
                        <td class="py-3 px-5" :class="[warning.threshold == 'max'? 'text-red-600':'text-yellow-600']">{{warning.sensor}}</td>
                        <td class="py-3 pl-5 pr-6 text-right" :class="[warning.threshold == 'max'? 'text-red-700':'text-yellow-600']">{{warning.value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="page" @get-page="getData($event)"></paginate>
        </div>
        <early-warning :show="modal" @onClose="modal=false"></early-warning>
    </div>
</template>

<script>
import Calendar from '../components/calendar.vue'
import tooltip from '../components/tooltip.vue'
import EarlyWarning from '../components/earlyWarning.vue'
import DropdownSegment from '../components/DropdownSegment.vue'
import DropdowStation from '../components/DropdownStation.vue'
import Dropdown from '../components/Dropdown.vue'
import axios from 'axios'
import {moment} from '../moment'
import paginate from '../components/paginate.vue'
export default {
    components: {Calendar, tooltip, EarlyWarning,DropdownSegment, DropdowStation, Dropdown, paginate},
    data () {
        return {
            isLoading: false,
            modal: false,
            date: '',
            label: '',
            segmentId: '',
            stations: '',
            stationId: '',
            filter: 'semua',
            parameters: {
                ph: '',
                do: '',
                cond: '',
                turb: '',
                temp: '',
                salt: '',
                dept: '',
            },
            sensor: [],
            sortKey: 'addedAt',
            sortOrder: 'desc',
            warnings: [],
            page: ''
        }
    },
    mounted () {
        this. date = new Date()
        this.label = 'Hari ini'
        this.getData()
    },
    methods: {
        getData (e) {
            this.isLoading = true
            axios.get('/warnings', {
                params: {
                    page: e,
                    sortKey: this.sortKey,
                    sortOrder: this.sortOrder,
                    date: this.date,
                    label: this.label,
                    segmentId: this.segmentId,
                    stationId: this.stationId,
                    filter: this.filter,
                    sensor: this.sensor
                }
            })
            .then(res => {
                window.scrollTo(0, 0)
                this.isLoading = false
                this.warnings = res.data.data
                this.page = res.data.pages
            })
        },
        getDate (d) {
            return moment(d)
        },
        onSelectDate (d, l) {
            this.date = d
            this.label = l
            this.$nextTick(() => {
                this.getData()
            })
        },
        onSelectSegment (id, stations) {
            this.segmentId = id
            this.stations = stations
        },
        onSelectStation (s) {
            this.stationId = s
            this.$nextTick(() => {
                this.getData()
            })
        },
        onFilter (val) {
            this.filter = val
            this.$nextTick(() => {
                this.getData()
            })
        },
        onParameter (val) {
            this.sensor.push(val)
            switch(val) {
                case "pH":
                    this.parameters.ph = val
                    break
                case "DO":
                    this.parameters.do = val
                    break
                case "COND":
                    this.parameters.cond = val
                    break
                case "TURB":
                    this.parameters.turb = val
                    break
                case "TEMP":
                    this.parameters.temp = val
                    break
                case "SALT":
                    this.parameters.salt = val
                    break
                case "DEPT":
                    this.parameters.dept = val
                    break
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
        onDeletParameter (val) {
            this.sensor.splice(this.sensor.indexOf(val), 1)
            switch(val) {
                case "pH":
                    this.parameters.ph =''
                    break
                case "DO":
                    this.parameters.do =''
                    break
                case "COND":
                    this.parameters.cond =''
                    break
                case "TURB":
                    this.parameters.turb =''
                    break
                case "TEMP":
                    this.parameters.temp =''
                    break
                case "SALT":
                    this.parameters.salt =''
                    break
                case "DEPT":
                    this.parameters.dept =''
                    break
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
         sortBy (key) {
            if(key == this.sortKey) {
                this.sortOrder = (this.sortOrder == 'desc') ? 'asc': 'desc'
            } else {
                this.sortKey = key
                this.sortOrder = 'desc'
            }
            this.$nextTick(() => {
                this.getData()
            })
        },
    }
}
</script>