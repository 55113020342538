<template>
    <div>
        <transition name="modal" mode="out-in">
            <div v-show="show" class="fixed inset-0 z-30 overflow-y-auto">
                <div class="flex justify-center">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity backdrop-filter backdrop-blur-sm" aria-hidden="true"></div>
                    <transition name="slide" >
                        <div v-show="show" class="transform flex justify-center my-8 w-full">
                            <form @submit.prevent="onSubmit" class="w-3/5 bg-gray-800 p-5 rounded-sm text-gray-400">
                                <div class="flex items-center justify-between mb-5">
                                    <div>BAKU MUTU</div>
                                    <a @click.prevent="$emit('onClose')" href="#" class="text-red-600 hover:text-red-700"><i class="icon-cancel-square2"></i></a>
                                </div>

                                <div class="grid grid-cols-2 gap-20">
                                    <div class="col-span-1">
                                        <legend class="text-xs border-b border-gray-700 pb-1 mb-5">AMBANG BATAS MINIMAL</legend>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min pH</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minPh" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min DO</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minDo" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min Cond</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minCond" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min Turb</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minTurb" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min Temp</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minTemp" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min Salt</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minSalt" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">min Dept</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.minDept" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1">
                                        <legend class="text-xs border-b border-gray-700 pb-1 mb-5">AMBANG BATAS MAKSIMAL</legend>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max pH</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxPh" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max DO</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxDo" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max Cond</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxCond" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max Turb</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxTurb" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max Temp</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxTemp" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max Salt</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxSalt" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                        <div class="grid grid-cols-5 items-center mb-3">
                                            <div class="col-span-2"><label for="">max Dept</label></div>
                                            <div class="col-span-3">
                                                <autonumeric v-model="form.maxDept" class="form-control bg-gray-700 border-gray-600 text-right" :options="{modifyValueOnWheel : false}"></autonumeric>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right mt-5">
                                    <button type="submit" class="h-9 px-5 bg-green-600 text-green-50 hover:bg-green-700 rounded-sm relative" :disabled="isDisabled">SIMPAN
                                        <div v-show="isDisabled" class="absolute bg-green-300 w-full h-full  inset-0 rounded-sm flex items-center justify-center">
                                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div>
                                    </button>
                                </div>

                            </form>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import axios from 'axios'
import Autonumeric from '../components/Autonumeric.vue'
export default {
    components:  {Autonumeric},
    props: {
        show: Boolean
    },
    data () {
        return {
            isDisabled: false,
            form: {
                minPh: 0,
                minDo: 0,
                minCond: 0,
                minTurb: 0,
                minTemp: 0,
                minSalt: 0,
                minDept: 0,
                maxPh: 0,
                maxDo: 0,
                maxCond: 0,
                maxTurb: 0,
                maxTemp: 0,
                maxSalt: 0,
                maxDept: 0,
            }
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            axios.get('/earlyWarning')
            .then(res => {
                if(res.data) {
                    this.form = res.data
                }
            })
        },
        onSubmit () {
            this.isDisabled = true
            axios.post('/earlyWarning/create', this.form)
            .then(res => {
                this.isDisabled = false
                this.form = res.data
                this.$emit('onClose')
            })
        }
    }
}
</script>